
    export default {
        name: "IconChevronDown",
        props: {
            fill: {
                default: "#111827",
                type: String
            }
        }
    }
