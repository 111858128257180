/**
 * @author Jason Curren
 * @description canvas preview for editing products mode
 * ================================================================================================
 */
import Vue from 'vue';
import { createBlobFromCanvas, createTransparentPngBlob } from "@/utils/ImageProcessingUtils";

export const creatorCanvasPreview = {
    props: {
        areaPreview: {
            default: "canvas-preview-area",
            type: String
        },
        idPreview: {
            default: "canvas-preview-ctx",
            type: String
        },
        imageIdPreview: {
            default: "canvas-preview-img",
            type: String
        },
    },
    data() {
        return {
            canvasPreviewImages: [],
            backCachePreview: [],
            frontCachePreview: [],
            productPrintAreaPreview: {
                backHeightMm: 0,
                heightMm: 0,
                widthMm: 0,
                backHeightPixels: 0,
                heightPixels: 0,
                widthPixels: 0,
                backHeightPrintPixels: 0,
                heightPrintPixels: 0,
                widthPrintPixels: 0,
                backHeightConverted: 0,
                heightConverted: 0,
                widthConverted: 0,
                widthDivided: 0,
                backScaledPercentage: 0,
                scaledPercentage: 0,
                imageHeight: 0,
                imageWidth: 0,
                imageRenderedHeight: 0,
                imageRenderedWidth: 0,
                imageScaledPercentage: 0,
                offsetAngleValue: 0
            },
            imagePreview: null,
            previewLoading: false,
            spreadPreview: 0,
            sizePreview: 0,
            selectedImagesPreview: {},
            selectedPreviewImage: {
                index: 0,
                url: ""
            },
            selectedPreviewSide: 0,
            selectedPreviewSideOnACylindricalProduct: 0,
            sidesInTheirOrder: [],
            isProcessingProductForEditing: false
        }
    },
    methods: {
        async addImagePreview(image) {
            await new this.$fabric.Image.fromURL(`/${image.image_resized || image.location}`, async img => {
                await this.canvasPreview.add(img.set(image));
            });
        },
        async addFabricPreviewImage(data) {
            const { angle, height, left, top, scaleX, scaleY, location, type, image_resized } = data;

            return {
                angle, height, left, top, scaleX, scaleY, type, location, image_resized
            };
        },
        async addTextPreview(text) {
            text.name = text.name || `text-${Date.now()}`;
            const textAdd = await new this.$fabric.IText(text.text, text);

            await this.canvasPreview.add(textAdd);
        },
        async addFabricPreviewText(data) {
            const {
                angle, height, left, top, scaleX, scaleY, fontFamily, text, textAlign, fill,
                underline, fontSize, type, width, fontUrl, centralTextPoint
            } = data;

            return {
                angle, height, left, top, scaleX, scaleY, type, text, textAlign, fill,
                underline, fontSize, fontFamily, width, fontUrl, centralTextPoint
            };
        },
        async makeMainPreviewImage(pickedPreviewImageSide) {
            if (this.isACylindricalProduct) {
                this.selectedPreviewSideOnACylindricalProduct = pickedPreviewImageSide;
            } else {
                this.selectedPreviewSide = pickedPreviewImageSide;
            }
        },
        previewThisColourImage(colour) {
            if (this.selectedProduct.colours && this.selectedProduct.images) {
                const position = this.selectedProduct.colours.indexOf(colour);

                if (position > -1 && this.selectedProduct.images[position]) {
                    this.selectedImagesPreview = this.selectedProduct.images[position];

                    this.openPreviewModal();
                }
            }
        },
        getProductImagePreview() {
            const { images } = this.selectedProduct;
            const img = images[0] || {};

            if (img.angles && img.angles.length) {
                this.selectedPreviewImage = img.angles[0];
                this.selectedPreviewImage.index = 0;
            } else if (img.front_public) {
                this.selectedPreviewImage.url = img.front_public;
            } else if (img.back_public) {
                this.selectedPreviewImage.url = img.back_public;
            }

            this.getProductValuesPreview();
        },
        getProductValuesPreview() {
            if (this.productPrintAreaPreview.heightPixels && this.productPrintAreaPreview.widthPixels) {
                setTimeout(() => this.getImagePreview(), 1000);
            }
        },
        getImagePreview() {
            this.imagePreview = document.getElementById(this.imageIdPreview);
            // console.log(document.getElementById(this.imageIdPreview));

            !this.imagePreview ? setTimeout(() => this.getImagePreview(), 1000) : this.processImagePreview();
        },
        async scalePreviewObjectsFromCache() {
            this.updateCache(this.angle);

            this.backCachePreview = [];
            this.frontCachePreview = [];

            const { widthPrintPixels } = this.productPrintArea;

            const calc = widthPrintPixels / this.canvas.getWidth();

            await this.backCache.forEach(data => {
                data.scaleX = data.scaleX * calc;
                data.scaleY = data.scaleY * calc;
                data.left = data.left * calc;
                data.top = data.top * calc;

                const type = data.hasOwnProperty("text")
                ? this.addProductText(data)
                : this.addProductImage(data);

                this.backCachePreview.push(type);
            });

            await this.frontCache.forEach(data => {
                data.scaleX = data.scaleX * calc;
                data.scaleY = data.scaleY * calc;
                data.left = data.left * calc;
                data.top = data.top * calc;

                const type = data.hasOwnProperty("text")
                ? this.addProductText(data)
                : this.addProductImage(data);

                this.frontCachePreview.push(type);
            });
        },
        async scalePreviewObjectsBackFromCache() {
            this.backCachePreview = [];
            this.frontCachePreview = [];

            const { widthConverted, widthPrintPixels } = this.productPrintArea;

            const calc = widthConverted / widthPrintPixels;

            await this.backCache.forEach(data => {
                data.scaleX = data.scaleX * calc;
                data.scaleY = data.scaleY * calc;
                data.left = data.left * calc;
                data.top = data.top * calc;

                const type = data.hasOwnProperty("text")
                ? this.addProductText(data)
                : this.addProductImage(data);

                this.backCachePreview.push(type);
            });

            await this.frontCache.forEach(data => {
                data.scaleX = data.scaleX * calc;
                data.scaleY = data.scaleY * calc;
                data.left = data.left * calc;
                data.top = data.top * calc;

                const type = data.hasOwnProperty("text")
                ? this.addProductText(data)
                : this.addProductImage(data);

                this.frontCachePreview.push(type);
            });

            // Cylindrical products don't have a small preview canvas,
            // so we shouldn't call this for them (also, it will break).
            if (!this.isACylindricalProduct) {
                await this.updatePreviewCanvasPositions();
            }
        },
        async generateImageFromBase64(base64String) {
            return new Promise((resolve, reject) => {
                const image = new Image();

                // Set crossOrigin to "Anonymous" to avoid tainted canvas issues
                // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
                image.crossOrigin = "Anonymous";

                image.onload = () => {
                    resolve(image);
                };

                image.onerror = (error) => {
                    reject(new Error('Error generating image from base64: ' + error));
                };

                image.src = base64String;
            });
        },
        async convertImageUrlToBase64(url, callback) {
            const fetchOptions = {
                headers: {
                    "Cache-Control": 'no-cache'
                }
            };

            fetch(url, fetchOptions)
                .then(response => {
                    if (response.ok) return response.blob();
                    throw new Error('Network response was not ok. Check network tab.');
                })
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const base64String = event.target.result;
                        callback(base64String);
                    };
                    reader.onerror = (error) => {
                        console.error('Error converting blob to Base64:', error);
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => console.error('Fetch error:', error));
        },
        async combineProductImageWithEditorCanvasCylindrical(
            editorCanvasBlobFront,
            productImageURL,
            productSideName = "frontImageData",
            productDiameter = null,
            wrapXStartingPoint = null,
            isTransparent = null
        ) {
            let necessaryRotationInDegrees = 90;

            switch (productSideName) {
                case "leftImageData":
                    necessaryRotationInDegrees = 0;
                    break;
                case "frontImageData":
                    necessaryRotationInDegrees = 90;
                    break;
                case "rightImageData":
                    necessaryRotationInDegrees = 180;
                    break;
                case "backImageData":
                    necessaryRotationInDegrees = 270;
                    break;
                default:
                    necessaryRotationInDegrees = 90;
                    break;
            }
            return new Promise((resolve, reject) => {
                const sourceCanvas = new Image();
                sourceCanvas.src = URL.createObjectURL(editorCanvasBlobFront);
                const offScreenCanvas = document.createElement('canvas');
                const offScreenCtx = offScreenCanvas.getContext('2d');

                sourceCanvas.onload = () => {

                    this.convertImageUrlToBase64(productImageURL, (base64) => {
                        const productImage = new Image();
                        // Needed to allow the canvas to be exported as an image.
                        // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
                        productImage.crossOrigin = "Anonymous";
                        productImage.src = base64;

                        productImage.onload = async () => {
                            offScreenCanvas.width = productImage.width;
                            offScreenCanvas.height = productImage.height;
                            offScreenCtx.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
                            

                            // Set a constant by which to adjust X dimension.
                            const referenceWidth = productImage.width;
                            const scaleFactorWidth = referenceWidth / sourceCanvas.width;
                            const scaleFactorHeight = referenceWidth / sourceCanvas.width;

                            const scaledWidth = offScreenCanvas.width;
                            let scaledHeight;

                            if (this.selectedCoord.height_area) {
                                scaledHeight = this.selectedCoord.height_area;
                            } else {
                                scaledHeight = sourceCanvas.height * scaleFactorWidth;
                            }

                            let cylinderRadius;

                            if (productDiameter) {
                                cylinderRadius = Math.floor(productDiameter / 2);
                            } else {
                                cylinderRadius = 115;
                            }
                            let xOffset;
                            // If we have wrapXStartingPoint we use that, otherwise we center horizontally.
                            if (wrapXStartingPoint) {
                                xOffset = wrapXStartingPoint + cylinderRadius;
                            } else {
                                xOffset = Math.floor(offScreenCanvas.width / 2) - 2;
                            }
                            const yOffset = this.selectedProduct.coords[0].front_y;
                            // const rotationRadians = 0.5 + (this.mainImageRotationAdjustment / 360) * 2 * Math.PI;
                            const rotationRadians = (0.5 + (-(necessaryRotationInDegrees) / 360) * 2 * Math.PI) + (Math.PI / 2);

                            if (isTransparent) {
                                for (let x = 0; x < scaledWidth; x++) {
                                    const angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 3 + rotationRadians; // Different from creatorCanvas.
                                    const xOnCylinder = Math.cos(angle) * cylinderRadius;
                                    const yOnCylinder = Math.sin(angle) * 16;
        
                                    const sourceX = (x / scaleFactorWidth) / (offScreenCanvas.width / referenceWidth);
        
                                    if (Math.sin(angle) >= 0) {
                                        offScreenCtx.globalAlpha = 0.5; // Adjust transparency level
                                        offScreenCtx.drawImage(sourceCanvas, sourceX, 0, 1, sourceCanvas.height,
                                                            xOffset + xOnCylinder, yOffset - yOnCylinder, 1.5, scaledHeight);
                                        offScreenCtx.globalAlpha = 1.0; // Reset to default for the next iteration of for
                                    }
                                }
                            }
                            offScreenCtx.drawImage(productImage, 0, 0, offScreenCanvas.width, offScreenCanvas.height);
                            for (let x = 0; x < scaledWidth; x++) {
                                const angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 3 + rotationRadians; // Different from creatorCanvas.
                                const xOnCylinder = Math.cos(angle) * cylinderRadius;
                                const yOnCylinder = Math.sin(angle) * 16;

                                const sourceX = (x / scaleFactorWidth) / (offScreenCanvas.width / referenceWidth);

                                if (Math.sin(angle) < 0) {
                                    offScreenCtx.drawImage(sourceCanvas, sourceX, 0, 1, sourceCanvas.height,
                                                        xOffset + xOnCylinder, yOffset - yOnCylinder, 1.5, scaledHeight);
                                }
                            }

                            let canvasBlob;
                            try {
                                canvasBlob = await createBlobFromCanvas(offScreenCanvas);
                                // console.log(roughSizeOfObject(canvasBlob));
                                resolve(canvasBlob);
                            } catch (error) {
                                console.error("Error creating blob:", error);
                            }
                        };

                        productImage.onerror = reject;
                    });
                }
            });
        },
        async openPreviewModal() {
            if (this.selectedProduct &&
                this.selectedProduct.is_cylindrical &&
                Object.keys(this.selectedImagesPreview.imagesFromDifferentAngles)?.length == 1)
            {
                this.selectedPreviewSideOnACylindricalProduct = 0;
            }
            this.modalType = "preview";
            this.previewLoading = true;

            // The scaling in the second line is done on the canvas, therefore shouldn't be
            // interrupted by the deselection on the first line until scalePreviewObjectsBackFromCache,
            // hence why we also use renderAll to render in place and not requestRenderAll to chain render.
            // (Consult fabric.js docs for the difference)
            this.canvas.discardActiveObject().renderAll();
            await this.scalePreviewObjectsFromCache();

            const { heightPrintPixels, widthPrintPixels } = this.productPrintArea;

            const design = {
                designBack: this.backCachePreview,
                designFront: this.frontCachePreview,
                fonts: [],
                height: heightPrintPixels,
                width: widthPrintPixels,
                selected_coord: this.selectedCoord,
                selected_images: this.selectedImagesPreview
            };

            if (this.selectedProduct && this.selectedProduct.is_cylindrical) {
                this.editorCanvasBlobFront = await createBlobFromCanvas(this.canvas.getElement());
                // downloadBlob(this.editorCanvasBlobFront, 'downloaded-image.png');

                if (design.selected_images.imagesFromDifferentAngles) {
                    let processedImages = [];
                    for (const [productSideName, productSideData] of Object.entries(design.selected_images.imagesFromDifferentAngles)) {
                        try {
                            let combinedImageDataUrl = await this.combineProductImageWithEditorCanvasCylindrical(
                                this.editorCanvasBlobFront,
                                productSideData.imageURL,
                                productSideName,
                                productSideData.productDiameter,
                                productSideData.wrapXStartingPoint,
                                design.selected_images.isTransparent
                            );
                            processedImages.push(URL.createObjectURL(combinedImageDataUrl));
                            this.sidesInTheirOrder.push(productSideName);
                        } catch (err) {
                            console.error('Error during image processing:', err);
                        }
                    }
                    this.previewLoading = false;
                    this.canvasPreviewImages = processedImages;
                } else {
                    try {
                        let combinedImageDataUrl = await this.combineProductImageWithEditorCanvasCylindrical(
                            this.editorCanvasBlobFront,
                            design.selected_images.front_public
                        );
                        // Vue.set is used to trigger Vue's reactivity when assigning to arrays.
                        // Not sure if needed but array includes redundant images if using .push instead.
                        Vue.set(this.canvasPreviewImages, 0, combinedImageDataUrl);
                    } catch (err) {
                        console.error('Error during image processing:', err);
                    } finally {
                        this.previewLoading = false;
                    }
                }
            } else {
                // We need an empty blob to represent an empty canvas.
                let emptyBlob = await createTransparentPngBlob();
                // Check which canvas has last been changed and create a blob of it.
                if (this.angle == 'front') {
                    this.editorCanvasBlobFront = await createBlobFromCanvas(this.canvas.getElement());
                } else if (this.angle == 'back') {
                    this.editorCanvasBlobBack = await createBlobFromCanvas(this.canvas.getElement());
                }
                let processedImages = [];
                try {
                    const combinedCanvasWithProductImageBlob = await this.combineProductImageWithEditorCanvas(
                        this.editorCanvasBlobFront || emptyBlob,
                        design.selected_images.front_public
                    );
                    // Push the URL to the new blob to processedImages.
                    processedImages.push(URL.createObjectURL(combinedCanvasWithProductImageBlob));

                    // If the product has a back side, process images for it too (or use the blank blob).
                    if (design.selected_images.back_public) {
                        const  combinedCanvasWithProductImageBlobBack = await this.combineProductImageWithEditorCanvas(
                            this.editorCanvasBlobBack || emptyBlob,
                            design.selected_images.back_public
                        );
                        
                        processedImages.push(URL.createObjectURL(combinedCanvasWithProductImageBlobBack));
                    }

                    // Soft-copy (and overwrite) the processed images to the images
                    // that will be previewed and stop the loading gif.
                    this.canvasPreviewImages = processedImages;
                    this.previewLoading = false;
                } catch (err) {
                    console.error('Error during image processing:', err);
                }
            }
            await this.scalePreviewObjectsBackFromCache();
        },
        async combineProductImageWithEditorCanvas(editorCanvasBlob, productImageURL) {
            return new Promise((resolve, reject) => {
                const sourceCanvas = new Image();
                sourceCanvas.src = URL.createObjectURL(editorCanvasBlob);
                const offScreenCanvas = document.createElement('canvas');
                const offScreenCtx = offScreenCanvas.getContext('2d');

                sourceCanvas.onload = () => {
                    this.convertImageUrlToBase64(productImageURL, (base64) => {
                        const productImage = new Image();
                        // Needed to allow the canvas to be exported as an image.
                        // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
                        productImage.crossOrigin = "Anonymous";
                        productImage.src = base64;
                
                        productImage.onload = async () => {
                            offScreenCanvas.width = productImage.width;
                            offScreenCanvas.height = productImage.height;
                            offScreenCtx.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
                            offScreenCtx.drawImage(productImage, 0, 0, offScreenCanvas.width, offScreenCanvas.height);
                    
                            let scaleFactor;
                            if (this.selectedCoord.height_area) {
                                scaleFactor = this.selectedCoord.height_area / sourceCanvas.height;
                                const scaledWidth = Math.round(sourceCanvas.width * scaleFactor);
                                offScreenCtx.drawImage(
                                    sourceCanvas, 
                                    this.selectedCoord.front_x,
                                    this.selectedCoord.front_y,
                                    scaledWidth,
                                    this.selectedCoord.height_area
                                );
                            } else {
                                scaleFactor = (offScreenCanvas.width - (this.selectedCoord.front_x * 2)) / sourceCanvas.width;
                                const scaledWidth = Math.round(sourceCanvas.width * scaleFactor);
                                const scaledHeight = Math.round(sourceCanvas.height * scaleFactor);
                                offScreenCtx.drawImage(
                                    sourceCanvas, 
                                    this.selectedCoord.front_x,
                                    this.selectedCoord.front_y,
                                    scaledWidth,
                                    scaledHeight
                                );
                            }
                            // Blob conversion
                            let canvasBlob;
                            try {
                                canvasBlob = await createBlobFromCanvas(offScreenCanvas);
                                // console.log(roughSizeOfObject(canvasBlob));
                                resolve(canvasBlob);
                            } catch (error) {
                                console.error("Error creating blob:", error);
                            }
                        };
                        productImage.onerror = reject;
                    });
                }
            });
        },
        
        async previewCanvasSet() {
            this.canvasPreview = new this.$fabric.StaticCanvas(this.idPreview);
        
            const width = this.productPrintAreaPreview.widthDivided
                ? this.productPrintAreaPreview.widthDivided
                : this.productPrintAreaPreview.widthConverted;
        
            this.canvasPreview.setHeight(this.productPrintAreaPreview.heightConverted);
            this.canvasPreview.setWidth(width);
        
            if (this.edit.selected) {
                await this.updateObjectPreviewLayers();
            }
        
            const watcher = document.getElementById(this.area);
        
            watcher.addEventListener("mousemove", async () => {
                if (!this.isProcessingProductForEditing) {
                    await this.updatePreviewCanvasPositions();
                }
            });
        },
        processImagePreview() {
            this.productPrintAreaPreview.imageHeight = this.imagePreview.naturalHeight;
            this.productPrintAreaPreview.imageWidth = this.imagePreview.naturalWidth;
            this.productPrintAreaPreview.imageRenderedHeight = this.imagePreview.height;
            this.productPrintAreaPreview.imageRenderedWidth = this.imagePreview.width;

            this.productPrintAreaPreview.imageScaledPercentage = 100 * this.productPrintAreaPreview.imageRenderedWidth / this.productPrintAreaPreview.imageWidth;

            this.setPreviewCanvasDimension();
            // For 3D type images (like bottles and mugs)

            if (this.selectedPreviewImage.width) {
                const divided = 100 * parseInt(this.selectedPreviewImage.width) / this.productPrintAreaPreview.imageScaledPercentage;
                this.productPrintAreaPreview.widthDivided = divided;
                this.setOffsetPreviewValue();
            } else {
                this.productPrintAreaPreview.offsetAngleValue = 0;
                this.productPrintAreaPreview.widthDivided = 0;
            }
            this.setCanvasPreview();

        },

        /**
         * If there is height_area, We need to set the height of preview canvas as height_area scaled down to preview canvas container height(400px) and take widthPixels, scale it down to get canvas width
         * else if no height_area but has front_x value, we take front_x value, double it, reduce it from preview canvas container width(400px) and set it as canvas width. Take heightPixels and scale it down to get canvas height
         * for no height_area and front_x, set width as half of canvas container width(200px) Take heightPixels and scale it down to get canvas height
         */
        setPreviewCanvasDimension() {
            if(this.selectedCoord.height_area) {
                const newHeight = this.selectedCoord.height_area * (this.productPrintAreaPreview.imageScaledPercentage / 100);
                this.productPrintAreaPreview.heightConverted = newHeight;

                const onePercent = 100 / this.productPrintAreaPreview.heightPixels;
                this.productPrintAreaPreview.scaledPercentage = newHeight * onePercent;

                this.productPrintAreaPreview.widthConverted = this.productPrintAreaPreview.widthPixels / 100 * this.productPrintAreaPreview.scaledPercentage;
            } else {
                this.setCanvasWidthPreview();
                this.scaleCanvasHeightPreview();
            }
            if(this.angle === "back" && this.productPrintAreaPreview.backHeightPixels) {
                this.scaleCanvasHeightBackPreview()
            }
        },
        async selectThisPreviewImage(image) {
            if (this.angle == 'front') {
                this.canvas.discardActiveObject().renderAll();
                this.editorCanvasBlobFront = await createBlobFromCanvas(document.getElementById('canvas-print'));
            } else if (this.angle == 'back') {
                this.canvas.discardActiveObject().renderAll();
                this.editorCanvasBlobBack = await createBlobFromCanvas(document.getElementById('canvas-print'));
            }
            this.selectedPreviewImage = image;

            if (image.angle) this.updateAngle(image.angle);

            if (this.productPrintAreaPreview.widthDivided) this.setOffsetPreviewValue();

            this.updateObjectPreviewLayers();
        },
        scaleCanvasHeightPreview() {
            const { heightPixels, scaledPercentage } = this.productPrintAreaPreview;

            this.productPrintAreaPreview.heightConverted = heightPixels / 100 * scaledPercentage;

            this.setCanvasPreview();
        },
        scaleCanvasHeightBackPreview() {
            const { backHeightPixels, scaledPercentage } = this.productPrintAreaPreview;

            this.productPrintAreaPreview.backHeightConverted = backHeightPixels / 100 * scaledPercentage;
        },
        setCanvasPreview() {
            this.imageLoading = false;

            this.previewCanvasSet();
        },
        setCanvasWidthPreview() {
            const {
                imageRenderedWidth, imageScaledPercentage, widthPixels
            } = this.productPrintAreaPreview;

            this.sizePreview = imageRenderedWidth / 2;
            const onePercent = 100 / widthPixels;
            if (this.selectedCoord.front_x) {
                const take = (parseInt(this.selectedCoord.front_x) / 100) * imageScaledPercentage;

                if (imageRenderedWidth - (take * 2) > 0) {
                    this.sizePreview = imageRenderedWidth - (take * 2);
                }
            }
            this.productPrintAreaPreview.scaledPercentage = this.sizePreview * onePercent;
            this.productPrintAreaPreview.widthConverted = this.selectedImages.angles && this.selectedImages.angles.length
            ? widthPixels / 100 * imageScaledPercentage
            : this.sizePreview;
        },
        setOffsetPreviewValue() {
            const { angles } = this.selectedImagesPreview;
            const {
                imageScaledPercentage, widthConverted, widthDivided
            } = this.productPrintAreaPreview;

            if (angles && angles.length) {
                // First imagee offset angle will always be 0 of the canvas area
                // --------------------------------------------------------------------------------
                let offset = 0;
                const images = angles.map(data => data.index);
                const position = images.indexOf(this.selectedPreviewImage.index);

                // Last image offset angle will always be the far right of the canvas area
                // --------------------------------------------------------------------------------
                if (position && (position + 1) === angles.length) {
                    const convert = parseInt(this.selectedPreviewImage.width) / 100 * imageScaledPercentage;

                    offset = widthConverted - convert;

                // Middle image to be completely center image if odd amount of images
                // --------------------------------------------------------------------------------
                } else if (angles.length % 2 !== 0 && position === (Math.floor(angles.length / 2))) {
                    const halfWay = widthConverted / 2;

                    offset = halfWay - (widthDivided / 2);

                // If we have a highlight point 1, then assume we want that to be a central part
                // which will fall on the second image
                // --------------------------------------------------------------------------------
                } else if (position === 1 && this.selectedCoord.highlight_point_1) {
                    const scale = parseInt(this.selectedCoord.highlight_point_1) / 100 * imageScaledPercentage

                    offset = (widthDivided * 2) - (scale / 2);

                // If we have a highlight point 2, then assume we want that to be a central part
                // which will fall on the second to last image
                // --------------------------------------------------------------------------------
                } else if (position === (angles.length - 2) && this.selectedCoord.highlight_point_2) {
                    const scale = parseInt(this.selectedCoord.highlight_point_2) / 100 * imageScaledPercentage;

                    offset = widthConverted - (scale / 2);

                // Last one, move preview images across left/right to right/left depending on
                // image amount
                // --------------------------------------------------------------------------------
                } else if (position) {
                    offset = position <= (Math.floor(angles.length / 2))
                    ? widthConverted / angles.length * position
                    : widthConverted - (widthDivided * (angles.length - position));
                }

                this.productPrintAreaPreview.offsetAngleValue = offset;
            }
        },
        setupPreviewCanvas() {
            this.imageLoading = true;
            this.productPrintAreaPreview = {
                ...this.productPrintArea, widthDivided: 0, offsetAngleValue: 0
            };

            const area = document.getElementById(this.areaPreview);

            this.spreadPreview = area.offsetWidth;

            this.getProductImagePreview();
        },
        async updatePreviewCanvasDelete(target) {
            if (this.mode === "editor" && !this.isACylindricalProduct) {
                const objects = await this.canvasPreview.getObjects();

                const { offsetAngleValue, widthConverted } = this.productPrintAreaPreview;

                const calc = widthConverted / this.canvas.getWidth();

                target.scaleX = target.scaleX * calc;
                target.scaleY = target.scaleY * calc;
                target.left = target.left * calc - offsetAngleValue;
                target.top = target.top * calc;

                const oldObj = JSON.stringify({
                    scaleX: target.scaleX, scaleY: target.scaleY, left: target.left,
                    top: target.top
                });

                await objects.forEach(async data => {
                    const newObj = JSON.stringify({
                        scaleX: data.scaleX, scaleY: data.scaleY, left: data.left,
                        top: data.top
                    });

                    if (newObj === oldObj) {
                        await this.canvasPreview.remove(data);
                        await this.canvasPreview.requestRenderAll();
                    }
                });
            }
        },
        async updatePreviewCanvasTarget(target, pos) {
            if (this.mode === "editor" && !this.isACylindricalProduct) {
                await this.updatePreviewCanvasDelete({ ...target });

                const object = await this.addFabricPreviewText({ ...target });

                const { offsetAngleValue, widthConverted } = this.productPrintAreaPreview;

                const calc = widthConverted / this.canvas.getWidth();

                object.scaleX = object.scaleX * calc;
                object.scaleY = object.scaleY * calc;
                object.left = object.left * calc - offsetAngleValue;
                object.top = object.top * calc;
                object.name= object.name || `text-${Date.now()}`

                const textAdd = await new this.$fabric.IText(object.text, object);

                await this.canvasPreview.insertAt(textAdd, pos, false);

                await this.canvasPreview.requestRenderAll();
            }
        },
        async updatePreviewCanvas() {
            if (this.mode === "editor" && !this.isACylindricalProduct) {
                const newObject = this.canvasObjects.length - 1;

                const object = this.canvasObjects[newObject].hasOwnProperty("text")
                ? await this.addFabricPreviewText(this.canvasObjects[newObject])
                : await this.addFabricPreviewImage(this.canvasObjects[newObject]);

                await this.updateObjectPreview(object);
            }
        },
        async updatePreviewCanvasPositions() {
            if (this.mode === "editor" && !this.isACylindricalProduct) {
                const objects = await this.canvasPreview.getObjects();
                const canvObjects = await this.canvas.getObjects();

                if (objects.length > 0 && canvObjects.length > 0) {    
                    for (let i = 0; i < canvObjects.length; i++) {
                        const object = canvObjects[i].hasOwnProperty("text")
                        ? await this.addFabricPreviewText(canvObjects[i])
                        : await this.addFabricPreviewImage(canvObjects[i]);
                        
                        await this.updateObjectPreviewPositions(object, objects[i]);
                    }
                }
            }
        },
        async updateObjectPreviewPositions(data, object) {
            const { offsetAngleValue, widthConverted } = this.productPrintAreaPreview;

            const calc = widthConverted / this.canvas.getWidth();

            data.scaleX = data.scaleX * calc;
            data.scaleY = data.scaleY * calc;
            data.left = data.left * calc - offsetAngleValue;
            data.top = data.top * calc;

            await object.set(data);
            await object.setCoords();

            await this.canvasPreview.requestRenderAll();
        },
        async updateObjectPreview(data) {
            if (!this.isACylindricalProduct) {
                const { offsetAngleValue, widthConverted } = this.productPrintAreaPreview;

                const calc = widthConverted / this.canvas.getWidth();

                data.scaleX = data.scaleX * calc;
                data.scaleY = data.scaleY * calc;
                data.left = data.left * calc - offsetAngleValue;
                data.top = data.top * calc;

                data.hasOwnProperty("text")
                ? await this.addTextPreview(data)
                : await this.addImagePreview(data);

                await this.canvasPreview.requestRenderAll();
            }
        },
        // For some reason this is called whenever you click
        // "edit" on a product saved as a draft or published
        async updateObjectPreviewLayers() {
            if (this.isProcessingProductForEditing || this.isACylindricalProduct) return;
            this.isProcessingProductForEditing = true;

            const objects = this.canvas.getObjects();
            this.canvasPreview.clear();

            let timer = 0;

            for (let canvasObject of objects) {
                const object = canvasObject.hasOwnProperty("text")
                    ? await this.addFabricPreviewText(canvasObject)
                    : await this.addFabricPreviewImage(canvasObject);

                setTimeout(() => {
                    this.updateObjectPreviewLayersPlace(object);
                }, timer);

                timer += 100;
            }

            this.canvasPreview.requestRenderAll();

            this.isProcessingProductForEditing = false;
        },
        async updateObjectPreviewLayersRefresh([...objects]) {
            if (!this.isACylindricalProduct) {
                let timer = 0;

                await this.canvasPreview.clear();

                objects.forEach(async (data, index) => {
                        this.imageLoading = true;

                        const object = data.hasOwnProperty("text")
                        ? await this.addFabricPreviewText(data)
                        : await this.addFabricPreviewImage(data);

                        setTimeout(() => {
                            this.updateObjectPreviewLayersPlace(object);
                        }, timer);

                        timer += 100;

                        if ((index + 1) === objects.length) {
                            this.canvasPreview.requestRenderAll();

                            this.imageLoading = false;
                        }
                });
            }
        },
        async updateObjectPreviewLayersPlace(data) {
            const { offsetAngleValue, widthConverted } = this.productPrintAreaPreview;
            const calc = widthConverted / this.canvas.getWidth();
        
            data.scaleX = data.scaleX * calc;
            data.scaleY = data.scaleY * calc;
            data.left = data.left * calc - offsetAngleValue;
            data.top = data.top * calc;
        
            if (data.hasOwnProperty("text")) {
                await this.addTextPreview(data);
            } else {
                await this.addImagePreview(data);
            }
        }
    },
    watch: {
        selectedImages() {
            this.selectedImagesPreview = { ...this.selectedImages };

            if (this.selectedImagesPreview.angles) {
                this.selectedImagesPreview.angles.forEach((data, index) => {
                    this.selectedImagesPreview.angles[index].index = index;
                });
            }
        }
    }
}