
    import { creatorAdd } from "@/mixins/creator/creatorAdd";
    import { creatorCache } from "@/mixins/creator/creatorCache";
    import { creatorEdit } from "@/mixins/creator/creatorEdit";
    import { creatorImage } from "@/mixins/creator/creatorImage";
    import { creatorText } from "@/mixins/creator/creatorText";
    import { creatorSetup } from "@/mixins/creator/creatorSetup";
    import { creatorUpdates } from "@/mixins/creator/creatorUpdates";
    import { creatorControls } from "@/mixins/creator/creatorControls";
    import { creatorRender } from "@/mixins/creator/creatorRender";
    import { creatorProduct } from "@/mixins/creator/creatorProduct";
    import { creatorLayers } from "@/mixins/creator/creatorLayers";
    import { creatorCanvasPreview } from "@/mixins/creator/creatorCanvasPreview";
    import SomethingWentWrongModal from "@/components/modal/SomethingWentWrongModal.vue";
    import ProductCheckbox from "@/components/product/ProductCheckbox.vue";

    export default {
        mixins: [
            creatorCache, creatorImage, creatorText, creatorSetup, creatorUpdates,
            creatorAdd, creatorEdit, creatorControls, creatorRender,
            creatorProduct, creatorLayers, creatorCanvasPreview
        ],
        name: "CreatorArea",
        components: {
            SomethingWentWrongModal,
            ProductCheckbox
        },
        props: {
            fonts: {
                default: () => ([]),
                type: Array
            },
            files: {
                default: () => ([]),
                type: Array
            },
            isDesignerStoreProduct: {
                default: false,
                type: Boolean
            },
            otherProductColours: {
                default: () => ([]),
                type: Array
            },
            otherProductFonts: {
                default: () => ([]),
                type: Array
            },
            url: {
                default: "",
                type: String
            },
            percentageDiscount: {
                default: 0.0,
            },
            shippingPercentageDiscount: {
                default: 0.0,
            },
            eventTypeName: {
                default: 'promo',
                type: String
            },
            isEditorBackgroundWhite: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                hasAgreedToFrontCustomText: false,
                hasAgreedToBackCustomText: false,
                hasTransparentPrint: false,
                printReadyFrontDesignBlob: null,
                printReadyBackDesignBlob: null,
                isFrontCustomisable: this.edit?.canvas_generate?.designFront?.some((item) => item.customisable),
                isBackCustomisable: this.edit?.canvas_generate?.designBack?.some((item) => item.customisable),
                editorCanvasBlobBack: null,
                editorCanvasBlobBackWithoutCustomText: null,
                editorCanvasBlobFront: null,
                editorCanvasBlobFrontWithoutCustomText: null,
                storeDefaultVat: this.productEdit.store_default_vat === 0 ? 0 : 1,
                frontDesignConsumer: `${this.$config.s3Bucket}/${this.$config.environment}/${this.edit?.artwork?.front}` || "",
                backDesignConsumer: `${this.$config.s3Bucket}/${this.$config.environment}/${this.edit?.artwork?.back}` || "",
                frontPreviewConsumer: `${this.$config.s3Bucket}/${this.$config.environment}/${this.edit?.artwork?.front_preview}` || "",
                backPreviewConsumer: `${this.$config.s3Bucket}/${this.$config.environment}/${this.edit?.artwork?.back_preview}` || ""
            }
        },
        computed: {
            allowSaveAsDraft() {
                // published products should not be allowed to be saved as draft
                if (this.productEdit.status !== "published" && this.eventTypeName !== 'shopify') {
                    return true;
                }
            },
            isExistingProduct() {
                // only existing products have selected
                if (this.edit.selected) {
                    return true
                } else {
                    return false
                }
            },
            marginToShow() {
                return this.selectedProductMarginStore.toFixed(2);
            },
            isACylindricalProduct() {
                return !!(this.selectedProduct?.is_cylindrical || false);
            },
            hasUserAgreedToAllCustomTextOnProduct() {
                // If the front is NOT customizable, then we ignore the checkbox.
                // Otherwise, the user MUST have agreed. Same logic in both cases.
                return (!this.isFrontCustomisable || this.hasAgreedToFrontCustomText) &&
                       (!this.isBackCustomisable || this.hasAgreedToBackCustomText);
            }
        },
        methods: {
            changeHasAgreedToFrontCustomText() {
                this.hasAgreedToFrontCustomText = !this.hasAgreedToFrontCustomText;
            },
            changeHasAgreedToBackCustomText() {
                this.hasAgreedToBackCustomText = !this.hasAgreedToBackCustomText;
            },
            updateStoreDefaultVat() {
              if(typeof this.storeDefaultVat !== 'undefined')  {
                  if(this.storeDefaultVat === 1) {
                      this.storeDefaultVat = 0;
                  }
                  else {
                      this.storeDefaultVat = 1;
                  }
              }
              else {
                  this.storeDefaultVat = 0;
              }
            },
            currencySymbol(region) {
                if(region === 'US') {
                    return '$';
                }
                else if(region === 'UK') {
                    return '£';
                }
                return '€';
            },
            leaveWithoutSavingConfirm() {
                this.modalType = "confirm";
            },
            leaveModal() {
                this.$emit("close-modal");
            },
            handleInput(event) {
                const value = event.target.value;
                if (value.includes('.')) {
                    this.storeProductQuantity = null;
                } else {
                    this.storeProductQuantity = value !== '' ? Number(value) : null;
                }
            },
            toggleEditorBackgroundColour() {
                this.isEditorBackgroundWhite = !this.isEditorBackgroundWhite;
            },
            saveEditedProduct() {
                if (this.productEdit.status !== "ready_to_publish_to_shopify" || this.eventTypeName !== 'shopify') {
                    this.saveProduct('published');
                }
                else {
                    this.saveProduct('ready_to_publish_to_shopify');
                }
            },
            productPrice() {
                let output = 0;
                if (this.selectedSetPrice) {
                    output = this.selectedSetPrice;
                }
                if (this.$store.state.event.notVatRegistered ) {
                    return output;
                }
                if (this.eventProduct.store_default_vat !== 1) {
                    return output;
                }
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(output) + parseFloat(vat)).toFixed(2);
            },
            productVat() {
                if (this.$store.state.event.notVatRegistered) {
                    return false;
                }
                if (this.eventProduct.store_default_vat !== 1) {
                    return false
                }

                let output = 0;
                output = this.selectedSetPrice;
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(vat)).toFixed(2);
            }
        }
    }
