
    export default {
        name: "CreatorProductColours",
        props: {
            productColours: {
                default: () => ([]),
                type: Array
            },
            selectedColors: {
                default: () => ([]),
                type: Array
            },
            selectedProduct: {
                default: () => ({}),
                type: Object
            },
            selectedRegion: {
                default: "UK",
                type: String
            },
            userSelectedProductColor: {
                default: "",
                type: String
            }
        },
        methods: {
            searchForColourTitle(color) {
                let output = "";

                if (this.selectedProduct.colour_names && this.selectedProduct.colour_names.length) {
                    const position = this.selectedProduct.colours.indexOf(color);

                    if (position > -1 && this.selectedProduct.colour_names[position]) {
                        output = this.selectedProduct.colour_names[position].toLowerCase();

                        return output

                    }
                }

                return output;
            },
            selectPreviewImage(colour, index) {
                // Add colour if preview image is selected but the colour isn't already in the array
                if (this.selectedColors.indexOf(colour === -1)) {
                    this.$emit("colour-pick", { colour, index, select: false });
                }

                this.$emit("preview-pick", colour, index);
            }
        }
    }
