
    export default {
        name: "ModalPreview",
        props: {
            angle: {
                default: "front",
                type: String
            },
            canvasPreviewImages: {
                default: () => ([]),
                type: Array
            },
            colorNames: {
                default: () => ([]),
                type: Array
            },
            isLoading: {
                default: false,
                type: Boolean
            },
            selectedColor: {
                default: "",
                type: String
            },
            selectedColors: {
                default: () => ([]),
                type: Array
            },
            selectedImages: {
                default: () => ({}),
                type: Object
            },
            selectedPreviewImage: {
                default: () => ({}),
                type: Object
            },
            isACylindricalProduct: {
                type:Boolean,
                default: false
            },
            selectedPreviewSide: {
                type: Number,
                default: 0
            },
            selectedPreviewSideOnACylindricalProduct: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                pickedPreview: this.isACylindricalProduct ? 1 : 0,
                showColours: false
            }
        },
        computed: {
            findColour() {
                let output = "";

                if (this.selectedColor && this.selectedColors.length && this.colorNames.length) {
                    const position = this.selectedColors.indexOf(this.selectedColor);

                    if (position > -1) {
                        output = this.colorNames[position].replace(/_/g, " ");
                    }
                }

                return output;
            },
            isMainImage() {
                if (this.isACylindricalProduct) {
                    return this.pickedPreview == this.selectedPreviewSideOnACylindricalProduct;
                } else {
                    return this.pickedPreview == this.selectedPreviewSide;
                }
            }
        },
        mounted() {
            this.pickedPreview = this.isACylindricalProduct ? this.selectedPreviewSideOnACylindricalProduct : this.selectedPreviewSide;
        },
        methods: {
            downloadImage(image) {
                const link = document.createElement("a");

                link.download = "preview-image.png";
                link.href = image;

                link.click();
            },
            makeMainPreviewImage() {
                // Code from Jason, need to check the front-to-back relationship to see if relevant.
                if (this.isACylindricalProduct) {
                    this.$emit("make-main-preview-image", this.pickedPreview);
                } else {
                    this.$emit("make-main-preview-image", this.pickedPreview);
                }
            },
            searchForColourTitle(index) {
                let output = "";

                if (this.colorNames[index]) {
                    output = this.colorNames[index].toLowerCase();

                    return output
                }

                return output;
            },
            scrollImages(position) {
                if (!position && this.pickedPreview) {
                    this.pickedPreview--;
                } else if (this.pickedPreview !== this.canvasPreviewImages.length - 1) {
                    this.pickedPreview++;
                }
            }
        }
    }
