/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorText = {
    data() {
        return {
            customTextForConsumerCanvas: null,
            activePosition: {},
            selectedText: {},
            consumerSelectedText: 0,
            tempText: ""
        }
    },
    computed: {
        checkCurrentSelectedAngleHasCustomtext() {
            let output = false;

            const objects = this.canvas.getObjects();

            objects.forEach(data => {
                if (data.customisable && (data.customisable == 1 || data.customisable === true)) {
                    output = true;
                }
            });

            return output;
        },
        combinedFonts() {
            const fonts = [];
            const output = [];

            this.$store.state.fonts.safe.forEach(font => {
                if (font && fonts.indexOf(font.family) === -1) {
                    fonts.push(font.family);
                    output.push(font);
                }
            });

            this.fonts.forEach(font => {
                if (font && fonts.indexOf(font.family) === -1) {
                    fonts.push(font.family);
                    output.push(font);
                }
            });

            this.finalDesign.fonts.forEach(font => {
                if (font && fonts.indexOf(font.family) === -1) {
                    fonts.push(font.family);
                    output.push(font);
                }
            });

            this.otherProductFonts.forEach(font => {
                if (font && fonts.indexOf(font.family) === -1) {
                    fonts.push(font.family);
                    output.push(font);
                }
            });

            return output;
        }
    },
    methods: {
        addTextCreatorEventListeners(text) {
            text.on({
                "deselected": this.unselectText,
                "moving": this.updatePreviewCanvasPositions,
                "scaling": this.updatePreviewCanvasPositions,
                "rotating": this.selectTextRotating
            });
        },
        addTextCreatorConsumerEventListeners(text) {
            text.on({
                "deselected": this.unselectText,
                "selected": this.selectText,
            });
        },
        addTextCheck(val) {
            const active = this.canvas.getActiveObject() || false;

            !active ? this.addText(val) : this.addUpdateText(active, val);
        },
        addText(val) {
            const { value } = val;

            const text = new this.$fabric.IText(value, {
                name: `text-${Date.now()}`,
                editable: false,
                fontFamily: "Roboto",
                objectCaching: false,
                dirty: true,
                statefullCache: false,
                lockScalingFlip: true,
                selected: true,
                fontSize: this.addTextFontSize(),
                fontStyle: "normal",
                fontWeight: "normal",
                underline: false,
                textAlign: "center",
                centeredScaling: true,
                fontUrl: this.$store.state.fonts.safe[1].files.regular,
                customisable: this.asset === "text" ? "1" : "",
                lineHeight: 1.16,
            });

            text.setControlsVisibility({
                mb: false,
                ml: false,
                mr: false,
                mt: false,
                mtr: false,
            });

            this.canvas.add(text);
            this.canvas.centerObject(text);
            this.canvas.setActiveObject(text);

            this.addTextCreatorEventListeners(text);

            if (this.mode === "editor" && !this.isACylindricalProduct) this.updatePreviewCanvas();
        },
        addTextFontSize(size = 30) {
            return size / 100 * this.canvasPercent;
        },
        addUpdateText(active, val) {
            let { key, value } = val;

            if (key === "shadow") {
                value = {
                    color: value,
                    offsetX: 5,
                    offsetY: 5,
                };
            }

            if (key !== "position") {
                this.updateTextObject(key, active, value);
            } else {
                this[`update${value}Align`](active);
            }
            // If contents of the text object are empty string, delete it from the canvas.
            if (this.mode === "editor" && value === '') {
                this.canvas.discardActiveObject().renderAll();
                this.canvas._objects = this.canvas._objects.filter(obj => {
                    if (obj.hasOwnProperty('text') && obj.text === '') {
                        return false;
                    }
                    return true;
                });
                this.canvas.renderAll();
                this.updatePreviewCanvasPositions();
                return;
            }

            if (key !== "text") this.canvas.requestRenderAll();

            this.selectedText = { ...active };

            this.updatePreviewCanvasPositions();
        },
        selectText({ target }) {
            this.selectedImage = {};
            this.selectedText = {};

            setTimeout(() => {
                const check = this.canvas.getActiveObject() || false;

                if (check) {
                    this.activePosition = { ...check.getCenterPoint() };
                    this.selectedText = { ...check };
                    this.asset = this.add = check.customisable ? "text" : "fixed";
                }
            }, 100);
        },
        unselectText() {
            if (this.mode !== "consumer") {
                this.activePosition = {};
                this.selectedText = {};

                if (!this.tempText && this.add && (this.add === "text" || this.add === "fixed")) {
                    this.updateBoxtype("");
                }
            }
        },
        selectTextRotating() {
            const active = this.canvas.getActiveObject() || false;

            if (active) this.selectedText = { ...active };
        },
        updateAngle(active, angle) {
            active.rotate(angle);
        },
        updateBottomAlign(active) {
            const scaled = active.height * active.scaleY || 1;

            active.top = this.angle === "back" && this.productPrintArea.backHeightConverted
            ? this.productPrintArea.backHeightConverted - scaled
            : this.productPrintArea.heightConverted - scaled;

            this.updatePreviewCanvasPositions();
        },
        updateLeftAlign(active) {
            active.left = 0;

            this.updatePreviewCanvasPositions();
        },
        updateHorizontalAlign(active) {
            this.canvas.centerObjectH(active);

            this.updatePreviewCanvasPositions();
        },
        updateRightAlign(active) {
            const scaled = active.width * active.scaleX || 1;

            active.left = this.productPrintArea.widthConverted - scaled;

            this.updatePreviewCanvasPositions();
        },
        updateTopAlign(active) {
            active.top = 0;

            this.updatePreviewCanvasPositions();
        },
        updateVerticalAlign(active) {
            this.canvas.centerObjectV(active);

            this.updatePreviewCanvasPositions();
        },
        updateCompareText(active) {
            const position = { ...active.getCenterPoint() };

            let {
                angle, fontFamily, height, left, textAlign, top, width,
                fontSize, fontStyle, fontWeight, underline, customisable,
                shadow, stroke, fill, scaleX, scaleY, fontUrl
            } = active;

            let textSettings = {
                name: `text-${Date.now()}`,
                editable: false, objectCaching: false,
                dirty: true, statefullCache: false, lockScalingFlip: true,
                selected: true, centeredScaling: true, angle, fontFamily,
                fontSize, height, left, textAlign, top, width, fontStyle,
                fontWeight, underline, customisable, shadow, stroke, fill,
                scaleX, scaleY, fontUrl
            };

            const objects = this.canvas.getObjects();
            const pos = objects.indexOf(active);

            this.canvas.remove(active);

            this.updatePreviewCanvasDelete(active);

            if (this.mode === "consumer") {
                textSettings.hoverCursor = customisable ? "text" : "default";
                textSettings.selected = false;
                textSettings.selectable = customisable ? true : false;

                if (customisable) {
                    const lock = [
                        "lockMovementX", "lockMovementY", "lockRotation", "lockScalingFlip",
                        "lockScalingX", "lockScalingY", "lockSkewingX", "lockSkewingY"
                    ];

                    lock.forEach(data => textSettings[data] = true);
                }
            }

            const text = new this.$fabric.IText(this.tempText, textSettings);

            if (this.mode === "consumer") {
                text.setControlsVisibility({
                    bl: false, br: false, mb: false, ml: false, mr: false, mt: false,
                    mtr: false, tl: false, tr: false
                });

                this.addTextCreatorConsumerEventListeners(text);
            } else {
                text.setControlsVisibility({
                    mb: false, ml: false, mr: false, mt: false, mtr: false
                });

                this.addTextCreatorEventListeners(text);
            }

            this.canvas.insertAt(text, pos, false);
            this.canvas.setActiveObject(text);

            this.updatePreviewCanvasTarget(text, pos);

            this.tempText = "";

            const center = { ...text.getCenterPoint() };

            const x = position.x - center.x;
            const y = position.y - center.y;

            text.left = text.left + x;
            text.top = text.top + y;

            text.setCoords();
        },
        updateTextObject(key, active, value) {
            if (key === "styling") {
                key = value;
                value = this[`${value}Check`](active);
            }

            if (key === "fontFamily") {
                this.combinedFonts.forEach(data => {
                    if (data.family && data.family === value) {
                        active.fontUrl = data.files.regular;
                    }
                });
            }

            if (key === "text") {
                this.activePosition = { ...active.getCenterPoint() };

                this.tempText = value;

                this.updateCompareText(active);
            } else {
                key === "angle" ? active.rotate(value) : active[key] = value;

                this.selectTextRotating();
            }
        },
        fontStyleCheck(active) {
            return active.fontStyle === "normal" ? "italic" : "normal";
        },
        fontWeightCheck(active) {
            return active.fontWeight === "normal" ? 800 : "normal";
        },
        underlineCheck(active) {
            return active.underline ? false : true;
        },
        updateCustomText(val) {
            const active = this.canvas.getActiveObject() || false;

            if (active) {
                this.addUpdateText(active, val);
            } else if (this.mode === "consumer") {
                let custom = {};

                const objs = this.canvas.getObjects();

                objs.forEach(data => {
                    if (data.customisable) custom = data;
                });

                if (custom.text || custom.text === "") {
                    this.selectedText = custom;

                    this.canvas.setActiveObject(custom);

                    this.addUpdateText(custom, val);
                }
            }
            this.customTextForConsumerCanvas = val.value;
        }
    }
}
