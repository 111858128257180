/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

import {
    createBlobFromCanvas,
    createPreviewOrPrintReadyCanvasBlobOrBase64,
    blobToBase64,
    imageUrlToBlob,
    isImageFullyTransparent
} from "@/utils/ImageProcessingUtils";

export const creatorAdd = {
    props: {
        compile: {
            default: false,
            type: Boolean
        },
        multiplier: {
            default: 2,
            type: Number
        },
        shopifyStore: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            addLoading: false,
            designScaledPreviews: {
                backCache: [],
                frontCache: []
            },
            exportType: "",
            finalDesign: {
                back: [],
                canvas_generate: {},
                colour_identifiers: {},
                colour_names: [],
                colour_size: {},
                colour_skus: {},
                colour_coords: {},
                fonts: [],
                front: [],
                images: {
                    back: "",
                    front: "",
                    front_preview: "",
                    back_preview: "",
                    front_preview_product: "",
                    back_preview_product: ""
                },
                dimensions: {},
                product_images: {},
                selected: {},
                selected_region: "",
                store: {}
            },
            selectedStatus: "",
            storeProductQuantity: 1,
            showSellingAtALossConfirmation: false,
            userSelectedProductColor: ""
        }
    },
    methods: {
        async addProductPromo() {
            this.finalDesign.store = {
                colour: this.selectedConsumerColor,
                size: this.selectedConsumerSize,
                sku: this.selectedConsumerSku,
                quantity: 1,
                product_id: this.eventProduct.id,
                identifier: this.selectedConsumerIdentifier,
                product_images: this.selectedImages,
            };

            await this.prepareConsumerDesignForOrder();

            if (!this.hasTransparentPrint) {
                const promoProduct = {
                    description: this.productDescription,
                    name: this.productName,
                    price: this.selectedSetPrice,
                    product_id: this.eventProduct.id,
                    products: this.finalDesign,
                    quantity: this.storeProductQuantity,
                    status: "published",
                    store_default_vat: this.storeDefaultVat,
                    store_selling_price: this.selectedSetPrice,
                }

                this.$emit('order-promo-product-as-consumer', promoProduct);
            }

            this.addLoading = false;
            this.loading = false;
        },
        async addProductSample() {
            this.finalDesign.store = {
                colour: this.selectedConsumerColor,
                size: this.selectedConsumerSize,
                sku: this.selectedConsumerSku,
                quantity: 1,
                product_id: this.eventProduct.id,
                identifier: this.selectedConsumerIdentifier,
                product_images: this.selectedImages,
            };

            await this.prepareConsumerDesignForOrder();

            if (!this.hasTransparentPrint) {
                const promoProduct = {
                    description: this.productDescription,
                    name: this.productName,
                    price: this.selectedSetPrice,
                    product_id: this.eventProduct.id,
                    products: this.finalDesign,
                    quantity: this.storeProductQuantity,
                    status: "published",
                    store_default_vat: this.storeDefaultVat,
                    store_selling_price: this.selectedSetPrice,
                }

                this.$emit('sample-product', promoProduct);
            }

            this.addLoading = false;
            this.loading = false;
        },
        findCustomTextValueInProductCache(productCacheArray) {
            //Either frontCache or backCache
            for (let item of productCacheArray) {
                if (item['customisable'] && item.text) {
                    return item.text;
                }
            }
            return null;
        },
        async addProductToCart() {
            this.addLoading = true;
            this.loading = true;
            if (this.storeProductQuantity < 1) {
                this.$toast.error(this.$tc('messages.quantity_must_be_at_least_1'));
                return;
            }

            if (this.storeProductQuantity > 99) {
                this.$toast.error(this.$tc('messages.quantity_must_be_at_less_than_100'));
                return;
            }

            this.finalDesign.store = {
                colour: this.selectedConsumerColor,
                size: this.selectedConsumerSize,
                sku: this.selectedConsumerSku,
                quantity: this.storeProductQuantity,
                product_id: this.eventProduct.id,
                identifier: this.selectedConsumerIdentifier,
                product_images: this.selectedImages,
                store_default_vat: this.eventProduct.store_default_vat,
            };


            await this.prepareConsumerDesignForOrder();

            if (!this.hasTransparentPrint) {
                this.$store.commit("basket/ADD_PRODUCT", {
                    description: this.productDescription,
                    name: this.productName,
                    price: this.selectedSetPrice,
                    product_id: this.eventProduct.id,
                    products: this.finalDesign,
                    quantity: this.storeProductQuantity,
                    status: "published",
                    store_default_vat: this.storeDefaultVat,
                    store_selling_price: this.selectedSetPrice,
                });
                // Clear the custom text preview images from the store, if they exist.
                this.$store.commit("product/UPDATE_CUSTOM_TEXT_FRONT_PREVIEW_IMAGE", null);
                this.$store.commit("product/UPDATE_CUSTOM_TEXT_BACK_PREVIEW_IMAGE", null);
                this.$emit("close-modal-after-adding-to-cart", true);
            }

            this.loading = false;
            this.addLoading = false;
        },
        async prepareConsumerDesignForOrder() {
            Object.assign(this.finalDesign, {
                front: this.edit.front || null,
                back: this.edit.back || null,
                fonts: this.edit.fonts,
                canvas_generate: this.edit.canvas_generate,
                dimensions: this.edit.dimensions,
                colour_coords: this.edit.colour_coords,
                colour_identifiers: this.edit.colour_identifiers,
                colour_size: this.edit.colour_size,
                colour_skus: this.edit.colour_skus,
                product_images: this.edit.product_images,
                selected: this.edit.selected,
                selected_region: this.edit.selected_region,
            });

            await this.processConsumerDesignsAndPreviews();
        },
        async processConsumerDesignsAndPreviews() {
            if (this.frontCache.length >= 1 && this.frontPreviewConsumer) {
                this.finalDesign.images.front = this.frontDesignConsumer;
                this.finalDesign.images.front_preview = this.frontPreviewConsumer;
            }
            if (this.backCache.length >= 1 && this.backPreviewConsumer) {
                this.finalDesign.images.back = this.backDesignConsumer;
                this.finalDesign.images.back_preview = this.backPreviewConsumer;
            }
            // If previews or designs === https://urmyne.s3.eu-west-2.amazonaws.com/dev/ then they don't exist
            // TODO: in such cases, make the string equal to ""

            if (this.frontCache.length >= 1) {
                this.finalDesign.images.front = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    this.$fabric,
                    this.frontCache,
                    this.productPrintArea,
                    "consumerPrint",
                    "base64",
                    this.edit.front
                );
                if (this.isFrontCustomisable) {
                    this.finalDesign.customTextFront = this.findCustomTextValueInProductCache(this.frontCache);
                    if (this.$store.state.product.customTextConsumerFrontPreview) {
                        this.finalDesign.images.front_preview = await blobToBase64(
                            this.$store.state.product.customTextConsumerFrontPreview
                        );
                    }
                }
                this.hasTransparentPrint = await isImageFullyTransparent(this.finalDesign.images.front);
                // downloadBlob(this.finalDesign.images.front, 'downloaded-ready-image.png');
            }
            if (this.backCache.length >= 1) {
                this.finalDesign.images.back = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    this.$fabric,
                    this.backCache,
                    this.productPrintArea,
                    "consumerPrint",
                    "base64",
                    this.edit.back
                );
                if (this.isBackCustomisable) {
                    this.finalDesign.customTextBack = this.findCustomTextValueInProductCache(this.backCache);
                    if (this.$store.state.product.customTextConsumerBackPreview) {
                        this.finalDesign.images.back_preview = await blobToBase64(
                            this.$store.state.product.customTextConsumerBackPreview
                        );
                    }
                }
                this.hasTransparentPrint = await isImageFullyTransparent(this.finalDesign.images.back);
                // downloadBlob(this.finalDesign.images.back, 'downloaded-ready-image.png');
            }
        },
        async addProduct(status = "published", isExistingProduct = false) {
            // Validate product design and color selection
            const isDesignValid = this.validateDesign();
            if (!isDesignValid) return;

            // Check if selling at a loss or Shopify variation limit
            if (status === "published" && this.isSellingAtALoss) {
                this.showSellingAtALossConfirmation = true;
                return;
            }

            if (this.selectedProductVariationCount > 100 && this.eventTypeName === "shopify") {
                this.showTooManyShopifyVariations = true;
                return;
            }

            this.exportType = this.determineExportType(status, isExistingProduct);
            this.addLoading = true;

            // Update product description and colour
            this.getProductDescription();
            this.updateChosenProductColour();

            // Generate print-ready blobs and preview images
            await this.generateDesignBlobs();

            this.loading = true;
            this.selectedStatus = status;
            this.compileFinalDesign();
        },
        /**
         * Validates the product design by checking if any objects exist on the canvas
         * and if at least one colour has been selected.
         *
         * @returns {boolean} Returns `true` if the design is valid; otherwise, `false`.
         */
        validateDesign() {
            const hasDesignObjects = this.canvas.getObjects().length || this.frontCache.length || this.backCache.length;
            const hasSelectedColors = this.selectedColors.length > 0;

            if (!hasDesignObjects) {
                this.$toast.error("Product design requires some objects adding");
                return false;
            }

            if (!hasSelectedColors) {
                const formattedColourSpelling = this.format_word_region("colour", this.selectedRegion);
                this.$toast.error(`Please select a ${formattedColourSpelling}`);
                return false;
            }

            return true;
        },
        /**
         * Determines the type of export action based on the product's status and type.
         *
         * @param {string} status - The status of the product (e.g., 'draft').
         * @param {boolean} isExistingProduct - Indicates whether the product already exists. POSSIBLY REDUNDANT!
         * @returns {string} Returns the export type: "sample", "save", or "add".
         */
        determineExportType(status, isExistingProduct) {
            if (this.eventType.type === "sample") {
                return "sample";
            } else if (status === "draft" && isExistingProduct) {
                return "save";
            } else {
                return "add";
            }
        },
        /**
         * Generates blobs for the product design.
         * For cylindrical products, generates print-ready and preview blobs for a front design.
         * For non-cylindrical products, generates blobs from the editor canvas
         * based on whichever angle is currently selected as well as print-ready blobs
         * from the front and/or back cache.
         *
         * @returns {Promise<void>} A promise that resolves once the blobs have been generated.
         */
        async generateDesignBlobs() {
            this.canvas.discardActiveObject().renderAll();

            // Generate blobs for cylindrical products
            if (this.selectedProduct.is_cylindrical) {
                this.editorCanvasBlobFront = await createBlobFromCanvas(this.canvas.getElement());

                this.printReadyFrontDesignBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    this.$fabric,
                    this.frontCache,
                    this.productPrintArea,
                    "editorPrint",
                    "blob",
                    this.edit.front
                );
                return;
            }

            // Generate blobs for non-cylindrical products
            if (this.angle === "front") {
                this.editorCanvasBlobFront = await createBlobFromCanvas(this.canvas.getElement());
                if (this.backCache?.length && !this.editorCanvasBlobBack) {
                    this.editorCanvasBlobBack = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                        this.$fabric,
                        this.backCache,
                        this.productPrintArea,
                        "editorPreview",
                        "blob",
                        this.edit.back
                    );
                }
            } else if (this.angle === "back") {
                this.editorCanvasBlobBack = await createBlobFromCanvas(this.canvas.getElement());
                if (this.frontCache?.length && !this.editorCanvasBlobFront) {
                    this.editorCanvasBlobFront = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                        this.$fabric,
                        this.frontCache,
                        this.productPrintArea,
                        "editorPreview",
                        "blob",
                        this.edit.front
                    );
                }
            }

            if (this.frontCache?.length) {
                this.printReadyFrontDesignBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    this.$fabric,
                    this.frontCache,
                    this.productPrintArea,
                    "editorPrint",
                    "blob",
                    this.edit.front
                );
            }

            if (this.backCache?.length) {
                this.printReadyBackDesignBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    this.$fabric,
                    this.backCache,
                    this.productPrintArea,
                    "editorPrint",
                    "blob",
                    this.edit.back
                );
            }
        },
        addProductImage(val) {
            const angle = val.angle || 0;

            const { height, filename, left, location, top, scaleX, scaleY, image_resized } = val;

            const src = val._element.currentSrc;

            return {
                angle, height, filename, left, top, scaleX, scaleY, src, type: "Image", location, image_resized, scaledHeight: val.getScaledHeight(), scaledWidth: val.getScaledWidth()
            }
        },
        addProductText(val) {
            const angle = val.angle || 0;
            const scaleX = val.scaleX || 0;
            const scaleY = val.scaleY || 0;

            let fontUrls = {};

            const {
                customisable, height, left, top, width, fontStyle, fontWeight, underline,
                fontSize, fontFamily, text, textAlign, stroke, shadow, fill, fontUrl,
                lineHeight
            } = val;

            const centralTextPoint = { ...val.getCenterPoint() };

            const httpsFontUrl = fontUrl;

            const allFonts = this.finalDesign.fonts.map(data => data.fontUrl);

            if (allFonts.indexOf(httpsFontUrl) === -1) {
                this.finalDesign.fonts.push({
                    fontFamily, fontUrl: httpsFontUrl
                });
            }

            return {
                fontFamily, height, left, top, text, textAlign, stroke, shadow, width, fill,
                type: "Text", angle, fontStyle, fontWeight, underline, fontSize, customisable,
                scaleX, scaleY, centralTextPoint, fontUrl: httpsFontUrl, fontUrls, lineHeight
            }
        },
        addProductSetAnoymous() {
            // Sets the crossOrigin property to anonymous to allow transfer of images over an API.
            this.canvas._objects.forEach((data, index) => {
                if (data._element) this.canvas._objects[index]._element.crossOrigin = "anonymous";
            });
        },
        createCanvasGenerate() {
            this.finalDesign.items = { ...this.productPrintArea };

            if (this.angle === "back") this.updateAngle("front");

            this.renderCache("front");

            this.scaleCanvasUpPrint(true);

            this.addProductSetAnoymous();

            this.scaleCanvasDown(true);

            this.renderCache("back");

            this.scaleCanvasUpPrint(true);

            this.addProductSetAnoymous();

            this.generateFullDesign();
        },
        generateFullDesign() {
            const names = this.selectedProduct.colour_names || [];
            const ids = this.selectedProduct.identifiers || [];

            if (names.length && names.length === ids.length && this.mode === "editor") {
                this.selectedIdentifiers.forEach(data => {
                    const position = ids.indexOf(data);

                    this.finalDesign.colour_names.push(names[position]);
                });
            }

            const {
                back, colour_coords, colour_names, fonts, front, items, product_images
            } = this.finalDesign;

            this.finalDesign.canvas_generate = {
                designBack: back,
                designFront: front,
                backHeight: items.backHeightPrintPixels,
                height: items.heightPrintPixels,
                width: items.widthPrintPixels,
                fonts,
                product_images,
                selected_images: this.selectedImages,
                colour_coords,
                selected_coord: this.selectedCoords,
                shopify: this.shopifyStore,
                colour_names,
                selected_preview_image: this.selectedPreviewImage
            }

            this.sendProducts();
        },
        async sendProducts() {
            const prod = {
                description: this.productDescription,
                name: this.productName,
                products: this.finalDesign,
                status: this.selectedStatus,
                store_selling_price: this.selectedSetPrice,
                store_default_vat: this.storeDefaultVat,
            };

            const newProduct = {};
            newProduct.description = this.productDescription;
            newProduct.name = this.productName;
            newProduct.status = this.selectedStatus;
            newProduct.products = this.finalDesign;
            newProduct.mainPreviewColour = String(this.userSelectedProductColor);
            newProduct.colourVariants = {};
            newProduct.store_selling_price = this.selectedSetPrice;
            newProduct.store_default_vat = this.storeDefaultVat;
            newProduct.frontDesign = this.printReadyFrontDesignBlob;
            newProduct.mainPreviewSide = null;

            const formData = new FormData();

            // We only want to add these images if the product is a rounded one with a set diameter in Akeneo
            if ((this.mode === "editor" || this.mode === "design") && !!prod.products.selected.is_cylindrical) {
                // prod.products holds information about each colour the user has chosen.
                // They can be found in prod.products.canvas_generate.product_images.#000000 (Example).
                //
                // Access the product_images property with data for each user-selected product color.
                const productImages = prod.products.canvas_generate.product_images;

                // In case the user never clicks on "View Mockups".
                if (this.sidesInTheirOrder.length == 0) {
                    for (const productSideName of Object.keys(this.selectedImagesPreview?.imagesFromDifferentAngles)) {
                        this.sidesInTheirOrder.push(productSideName);
                    }
                }

                if (Object.keys(this.selectedImagesPreview?.imagesFromDifferentAngles)?.length == 1)
                {
                    this.selectedPreviewSideOnACylindricalProduct = 0;
                }

                let selectedFinalMainImage = this.sidesInTheirOrder[this.selectedPreviewSideOnACylindricalProduct];

                // Properties for cylindrical products.
                newProduct.backDesign = null;
                newProduct.isCylindrical = true;

                switch (selectedFinalMainImage) {
                    case "leftImageData":
                        newProduct.mainPreviewSide = "leftPreview";
                        newProduct.products.canvas_generate.mainPreviewSide = "leftPreview";
                        break;
                    case "frontImageData":
                        newProduct.mainPreviewSide = "frontPreview";
                        newProduct.products.canvas_generate.mainPreviewSide = "frontPreview";
                        break;
                    case "rightImageData":
                        newProduct.mainPreviewSide = "rightPreview";
                        newProduct.products.canvas_generate.mainPreviewSide = "rightPreview";
                        break;
                    case "backImageData":
                        newProduct.mainPreviewSide = "backPreview";
                        newProduct.products.canvas_generate.mainPreviewSide = "backPreview";
                        break;
                    default:
                        newProduct.mainPreviewSide = "frontPreview";
                        newProduct.products.canvas_generate.mainPreviewSide = "frontPreview";
                        break;
                }

                for (const [chosenColourName, chosenColourData] of Object.entries(this.finalDesign.product_images)) {
                    newProduct.colourVariants[chosenColourName] = {};
                    if (chosenColourData.imagesFromDifferentAngles.frontImageData) {
                        newProduct.colourVariants[chosenColourName].frontPreview =
                            await this.combineProductImageWithEditorCanvasCylindrical(
                                this.editorCanvasBlobFront,
                                chosenColourData.imagesFromDifferentAngles.frontImageData.imageURL,
                                'frontImageData',
                                chosenColourData.imagesFromDifferentAngles.frontImageData.productDiameter,
                                chosenColourData.imagesFromDifferentAngles.frontImageData.wrapXStartingPoint,
                                chosenColourData.isTransparent
                            )
                    }
                    if (chosenColourData.imagesFromDifferentAngles.backImageData) {
                        newProduct.colourVariants[chosenColourName].backPreview =
                            await this.combineProductImageWithEditorCanvasCylindrical(
                                this.editorCanvasBlobFront,
                                chosenColourData.imagesFromDifferentAngles.backImageData.imageURL,
                                'backImageData',
                                chosenColourData.imagesFromDifferentAngles.backImageData.productDiameter,
                                chosenColourData.imagesFromDifferentAngles.backImageData.wrapXStartingPoint,
                                chosenColourData.isTransparent
                            )
                    }
                    if (chosenColourData.imagesFromDifferentAngles.leftImageData) {
                        newProduct.colourVariants[chosenColourName].leftPreview =
                            await this.combineProductImageWithEditorCanvasCylindrical(
                                this.editorCanvasBlobFront,
                                chosenColourData.imagesFromDifferentAngles.leftImageData.imageURL,
                                'leftImageData',
                                chosenColourData.imagesFromDifferentAngles.leftImageData.productDiameter,
                                chosenColourData.imagesFromDifferentAngles.leftImageData.wrapXStartingPoint,
                                chosenColourData.isTransparent
                            )
                    }
                    if (chosenColourData.imagesFromDifferentAngles.rightImageData) {
                        newProduct.colourVariants[chosenColourName].rightPreview =
                            await this.combineProductImageWithEditorCanvasCylindrical(
                                this.editorCanvasBlobFront,
                                chosenColourData.imagesFromDifferentAngles.rightImageData.imageURL,
                                'rightImageData',
                                chosenColourData.imagesFromDifferentAngles.rightImageData.productDiameter,
                                chosenColourData.imagesFromDifferentAngles.rightImageData.wrapXStartingPoint,
                                chosenColourData.isTransparent
                            )
                    }
                }

                // Append the entire structure to FormData.
                this.appendNestedDataToFormData(formData, newProduct);

            } else if (this.mode === "editor" || this.mode === "design") {
                // Regular products can have a backDesign. Check if product has back_public to apply it.
                newProduct.backDesign = this.backCache?.length >= 1 ? this.printReadyBackDesignBlob : null;

                for (const [chosenColourHex, chosenColourData] of Object.entries(this.finalDesign.product_images)) {
                    newProduct.colourVariants[chosenColourHex] = {};

                    if (chosenColourData.front_public && this.editorCanvasBlobFront) {
                        newProduct.colourVariants[chosenColourHex].frontPreview = await this.combineProductImageWithEditorCanvas(
                            this.editorCanvasBlobFront,
                            chosenColourData.front_public
                        )
                    } else if (this.edit?.artwork?.front_colours.some((colourURL) => colourURL.colour === chosenColourHex)) {
                        // If we don't have editorCanvasBlobFront, check if we're editing a product that has a saved front side.
                        const matchingColourObject = this.edit?.artwork?.front_colours.find(
                            (colourObject) => colourObject.colour === chosenColourHex
                        );
                        if (matchingColourObject) {
                            // If a matching object is found, plug its img property into imageUrlToBlob to get the last preview.
                            newProduct.colourVariants[chosenColourHex].frontPreview = await imageUrlToBlob(
                                `${this.$config.s3Bucket}/${this.$config.environment}/${matchingColourObject.img}`
                            );
                        }
                    }

                    if (chosenColourData.back_public && this.editorCanvasBlobBack) {
                        newProduct.colourVariants[chosenColourHex].backPreview = await this.combineProductImageWithEditorCanvas(
                            this.editorCanvasBlobBack,
                            chosenColourData.back_public
                        )
                    } else if (this.edit?.artwork?.back_colours.some((colourURL) => colourURL.colour === chosenColourHex)) {
                        // If we don't have editorCanvasBlobBack, check if we're editing a product that has a saved back side.
                        const matchingColourObject = this.edit?.artwork?.back_colours.find(
                            (colourObject) => colourObject.colour === chosenColourHex
                        );
                        if (matchingColourObject) {
                            // If a matching object is found, plug its img property into imageUrlToBlob to get the last preview.
                            newProduct.colourVariants[chosenColourHex].backPreview = await imageUrlToBlob(
                                `${this.$config.s3Bucket}/${this.$config.environment}/${matchingColourObject.img}`
                            );
                        }
                    }
                }
                this.appendNestedDataToFormData(formData, newProduct);
            }

            this.previewLoading = false;
            this.loading = false;
            // PRODUCTS SEND
            this.$emit(`new-${this.exportType}-product`, formData);
        },
        appendNestedDataToFormData(formData, data, parentKey = '') {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];
                    const formKey = parentKey ? `${parentKey}[${key}]` : key;

                    if (value instanceof Blob) {
                        // Append Blob objects (e.g., files, images)
                        formData.append(formKey, value, value.name || 'blob');
                    } else if (Array.isArray(value)) {
                        // Handle arrays, including empty ones
                        if (value.length === 0) {
                            // Append an empty array by sending the key without any value
                            formData.append(formKey, '');
                        } else {
                            // Append each element of the array
                            value.forEach((item, index) => {
                                if (typeof item === 'object' && item !== null) {
                                    // Recursively process nested objects or arrays
                                    this.appendNestedDataToFormData(formData, item, `${formKey}[${index}]`);
                                } else {
                                    // Append simple types (string, number, etc.)
                                    formData.append(`${formKey}[${index}]`, item);
                                }
                            });
                        }
                    } else if (typeof value === 'object' && value !== null) {
                        // Recursively process nested objects
                        this.appendNestedDataToFormData(formData, value, formKey);
                    } else {
                        // Append simple types (string, number, null, etc.)
                        formData.append(formKey, value);
                    }
                }
            }
        },
        async compileFinalDesign() {
            this.loading = true;

            this.finalDesign.selected_region = this.selectedRegion;


            this.selectedColors.forEach((colourHexString, index) => {
                this.finalDesign.colour_size[colourHexString] = this.selectedSizes[index];
                this.finalDesign.colour_identifiers[colourHexString] = this.selectedIdentifiers[index];
                this.finalDesign.colour_skus[colourHexString] = this.selectedSkus[index];
                this.finalDesign.product_images[colourHexString] = this.selectedImageUrls[index];
                this.finalDesign.colour_coords[colourHexString] = this.selectedCoords[index];
            });

            await this.compileProducts();
        },
        async compileProducts() {
            this.loading = true;
            this.addLoading = true;

            this.finalDesign.fonts = [];

            await this.updateCache(this.angle);

            this.designScaledPreviews.frontCache = [...this.frontCache];
            this.designScaledPreviews.backCache = [...this.backCache];

            if (this.backCache.length) {
                await this.updateAngle("back");

                await this.scaleCanvasUpPrint(true);

                this.backCache.forEach((data, index) => {
                    const type = data.hasOwnProperty("text")
                    ? this.addProductText(data)
                    : this.addProductImage(data);

                    // Stop blank text objects being added in, otherwise this will break the canvas
                    // on being edited
                    // ----------------------------------------------------------------------------
                    if ((type.type === "Text" && type.text) || (type.type === "Image" && type.location)) {
                        this.finalDesign.back.push(type);
                    }
                });

                this.scaleCanvasDown(true);
            }

            if (this.frontCache.length) {
                await this.updateAngle("front");

                this.selectedCoord.display_print_area
                ? await this.scaleCanvasUpDisplayPrint(true)
                : await this.scaleCanvasUpPrint(true);

                this.frontCache.forEach((data, index) => {
                    const type = data.hasOwnProperty("text")
                    ? this.addProductText(data)
                    : this.addProductImage(data);

                    // Stop blank text objects being added in, otherwise this will break the canvas
                    // on being edited
                    // ----------------------------------------------------------------------------
                    if ((type.type === "Text" && type.text) || (type.type === "Image" && type.location)) {
                        this.finalDesign.front.push(type);
                    }
                });
            }

            this.finalDesign.selected = await this.selectedProduct;

            if (this.userSelectedProductColor) {
                this.finalDesign.selected.user_selected_product_color = this.userSelectedProductColor;
            }

            this.scaleCanvasDown(true);

            this.createCanvasGenerate();
        },
        async saveProduct(status = "active") {
            // Validate product design and color selection
            const isDesignValid = this.validateDesign();
            if (!isDesignValid) return;

            if (this.isSellingAtALoss && (status === "published" || status === 'ready_to_publish_to_shopify')) {
                this.showSellingAtALossConfirmation = true;
                return;
            }

            if (this.selectedProductVariationCount > 100 && this.eventTypeName === 'shopify') {
                this.showTooManyShopifyVariations = true;
                return;
            }

            this.exportType = "save";
            this.addLoading = true;

            // Update product description and colour
            this.getProductDescription();
            this.updateChosenProductColour();

            // Generate print-ready blobs and preview images
            await this.generateDesignBlobs();

            this.loading = true;
            this.selectedStatus = status;
            this.compileFinalDesign();
        },
        // uploadTempFile(url, callback) {
        //     this.loading = true;

        //     if (url) {
        //         this.$axios.post(`/api/assets/creator-product-upload`, {
        //             url
        //         }).then(res => {
        //             const { data } = res.data;

        //             this[callback](data);
        //         }).catch(err => {
        //             console.log(err);
        //         });
        //     } else {
        //         this[callback]("");
        //     }
        // },
        getProductDescription() {
            if (this.tiptapEditor) {
                const html = this.tiptapEditor.getHTML();

                if (html) this.productDescription = html;
            }
        },
        updateChosenProductColour() {
            if (this.userSelectedProductColor) {
                const position = this.selectedColors.indexOf(this.userSelectedProductColor);

                if (position > -1) {
                    this.selectedColor = this.userSelectedProductColor;
                    this.selectedImages = this.selectedImageUrls[position];
                }
            }
        }
    },
    watch: {
        compile() {
            if (this.compile) this.addProduct();
        }
    }
}