export const creatorProduct = {
    props: {
        eventProduct: {
            default: () => ({}),
            type: Object
        },
        eventType: {
            default: () => ({}),
            type: Object
        },
        brandColors: {
            default: () => ([]),
            type: Array
        },
        productEdit: {
            default: () => ({}),
            type: Object
        },
        productDefaultMarkup: {
            default: 95,
            type: Number
        },
        selectedRegion: {
            default: "UK",
            type: String
        },
        sizesSortOrdering: {
            default: () => ([
                "onesize",
                "0_1M","1_2M", "0_3m", "0_6m", "3_6m", "4_6M", "6_9M", "6_12m", "9_12m", "12_18m", "18_24m", "24m", "24_36m",
                "3_4", "5_6", "7_8", "9_11", "12_13",
                "2yr","2_3Y","2_4Y","3_4Y", "4yr","4_6Y", "6yr","6_8Y", "7yr", "8yr","8_10Y", "9yr", '10yr', "11yr", "12yr", '13yr', "14yr", "15yr", "16yr",
                "xxs","xs", "s", "small", "m", "medium", "l", "large", "XLarge", "xl", "xxl", "3xl", "4xl",
                "5xl", "6xl", "7xl", "8xl"
            ]),
            type: Array
        },
        sizeFormatting: {
            default: () => ({
                "onesize": "Onesize",
                "0_1M": "0-1M", "1_2m":"1-2M", "0_3m":"0-3M", "0_6m":"0-6M", "3_6m":"3-6M", "4_6M": "4-6M", "6_9M": "6-9M",
                "6_12m": "6-12M", "9_12m": "9-12M", "12_18m": "12-18M", "18_24m": "18-24M", "24m": "24M", "2436m": "24-36M",
                "2yr": "2YR", "4yr": "4YR", "6yr": "6YR", "7yr": "7YR", "8yr": "8YR", "9yr": "9YR", "10yr": "10YR", "11yr": "11YR",
                "12yr" : "12yr", "13yr" : "13YR", "14yr" : "14YR", "15yr": "15YR",
                "23Y": "2-3YR","24Y": "2-4YR","3_4Y": "3-4YR","4_6Y": "4-6YR","6_8Y": "6-8YR", "8_10Y": "8-10YR",
                "3_4": "3-4", "5_6": "5-6", "7_8": "7-8", "9_11": "9-11", "12_13": "12-13",
                xxs:"XXS", xs: "XS", s: "S", small: "S", m: "M", medium: "M", l: "L",
                large: "L", XLarge: "XL", xl: "XL", xxl: "XXL", "3xl": "3XL", "4xl": "4XL",
                "5xl": "5XL", "6xl": "6XL", "7xl": "7XL", "8xl": "8XL"
            }),
            type: Object
        }
    },
    data() {
        return {
            currencyLookupFormat: {
                "EU": "€ EUR", "UK": "£ GBP", "US": "$ USD"
            },
            soloPrintPrice: 0,
            soloBlankPrice: 0,
            productName: "",
            productDescription: "",
            selectedColor: "",
            selectedImages: { back: "", front: "" },
            selectedSize: [],
            selectedColors: [],
            selectedCoord: {},
            selectedCoords: [],
            selectedIdentifiers: [],
            selectedImageUrls: [],
            selectedSetPrice: 0,
            selectedSizes: [],
            selectedConsumerSizes: [],
            selectedProductPrice: 0,
            selectedConsumerColor: "",
            selectedConsumerSize: "",
            selectedConsumerSku: "",
            selectedConsumerIdentifier: "",
            selectedSkus: [],
            selectedSkusSelected: [],
            showTooManyShopifyVariations: false,
        }
    },
    computed: {
        currencyBasedOnRegion() {
            return this.currencyLookupFormat[this.selectedRegion];
        },
        selectedColorSizes() {
            let output = [];

            if (this.selectedProduct.sizes && this.selectedColor) {
                const sizes = [];

                this.selectedProduct.sizes.forEach(data => {
                    const position = this.selectedProduct.colours.indexOf(this.selectedColor);

                    if (data.includes(this.selectedProduct.identifiers[position])) {
                        const size = data.replace(this.selectedProduct.identifiers[position], "").replace('_', '');

                        sizes.push(size);
                    }
                });

                const customSort = (a, b) => {
                    const indexA = this.sizesSortOrdering.indexOf(a);
                    const indexB = this.sizesSortOrdering.indexOf(b);

                    return indexA - indexB;
                }

                output = sizes.sort(customSort);
            }

            return output;
        },
        selectedProductVariationCount() {
            if (this.selectedProduct?.sizes?.length && this.selectedColors?.length) {
                const sizes = [];
                let count = 0;

                this.selectedColors.forEach(colour =>{
                    this.selectedProduct.sizes.forEach(data => {
                        const position = this.selectedProduct.colours.indexOf(colour);
                        if (data.includes(this.selectedProduct.identifiers[position])) {
                            count++;
                        }
                    });

                });
                return count;
            }

            return this.selectedColors.length;

        },
        selectedColorSizesConsumer() {
            let output = [];

            if (this.edit.colour_size) {
                output = Object.keys(this.edit.colour_size);
            }

            return output;
        },
        selectedSizesConsumer() {
            let output = [];

            if (this.selectedConsumerColor && this.edit.colour_size) {
                output = this.edit.colour_size[this.selectedConsumerColor] || [];
            }

            return output;
        },
        selectedSkusConsumer() {
            let output = [];

            if (this.selectedConsumerColor && this.edit.colour_skus) {
                output = this.edit.colour_skus[this.selectedConsumerColor];
            }

            return output;
        },
        selectedProductMarginStore() {
            return this.selectedSetPrice - this.selectedProductTradePrice;
        },
        selectedProductTradePriceIncludingVatEstimationAndShipping() {
            // if US, no clue
            // if uk/eu 20/21 percent
            // add basic shipping charge
            if (this.selectedRegion === 'UK') {
               return (this.selectedProductTradePrice + this.selectedProductBaseShipping) * 1.2;
            } else if (this.selectedRegion === 'EU') {
                return (this.selectedProductTradePrice + this.selectedProductBaseShipping) * 1.21;
            }

        },
        reccommendedVat() {
            if (this.selectedRegion === 'UK') {
                return 20;
            } else if (this.selectedRegion === 'EU') {
                return 21;
            }
        },
        selectedProductBaseShipping() {
            let shipping = 0.00;
            if (this.selectedRegion === 'UK') {
                shipping = 3.95;
            } else if (this.selectedRegion === 'EU') {
                shipping = 3.95;
            } else if (this.selectedRegion === 'US') {
                shipping = 8.00;
            }
            if (this.shippingPercentageDiscount != 0.0) {
                return parseFloat(shipping - (shipping * this.shippingPercentageDiscount / 100));
            }
            return shipping;
        },
        selectedProductTradePrice() {
            if (this.backCache.length >= 1 && this.frontCache.length >= 1)
            {
                const originalPrice = this.soloBlankPrice + (this.soloPrintPrice + this.soloPrintPrice);
                if (this.percentageDiscount != 0.0) {

                    // Return the discounted price
                    return parseFloat((originalPrice - (originalPrice * this.percentageDiscount / 100)).toFixed(2));
                }

                return parseFloat((this.soloBlankPrice + (this.soloPrintPrice + this.soloPrintPrice)).toFixed(2));
            }
            const originalPrice = this.soloBlankPrice + this.soloPrintPrice;

            if (this.percentageDiscount != 0.0) {

                // Return the discounted price
                return parseFloat((originalPrice - (originalPrice * this.percentageDiscount / 100)).toFixed(2));
            }
            return originalPrice;
        },
        selectedProductTradePriceOnePrint() {
            const originalPrice = this.soloBlankPrice + this.soloPrintPrice;
            if (this.percentageDiscount != 0.0) {
                return (originalPrice - (originalPrice * this.percentageDiscount / 100)).toFixed(2);
            }
            return this.soloBlankPrice + this.soloPrintPrice;
        },
        showPlusPrintPrice() {
           return this.backCache.length >= 1 && this.frontCache.length >= 1
        },
        isSellingAtALoss() {
            if (this.isDesignerStoreProduct) {
                return this.selectedProductMarginStore < 0;
            }
            return false
        },
    },
    methods: {
        checkForEvent() {
            if (this.eventProduct.name) this.productName = this.eventProduct.name;

            if (this.eventProduct.event_prices && this.eventProduct.event_prices.length) {
                this.setProductPrice(this.eventProduct.event_prices[0]);
            }

            if (this.eventProduct.description) {
                this.productDescription = this.eventProduct.description;
            }
        },
        checkUserSelectedColor() {
            if (!this.userSelectedProductColor) {
                this.userSelectedProductColor = this.selectedColor;
            }

            if (!this.selectedColors.length) {
                this.userSelectedProductColor = "";
            } else if (this.selectedColors.indexOf(this.userSelectedProductColor) === -1) {
                this.userSelectedProductColor = this.selectedColor;
            }
        },
        checkMountedUserSelectedColor() {
            if (this.userSelectedProductColor !== this.selectedConsumerColor && this.mode === "consumer") {
                this.selectThisColorConsumer(this.userSelectedProductColor, false);
            } else if (this.userSelectedProductColor !== this.selectedColor) {
                const position = this.selectedColors.indexOf(this.userSelectedProductColor);

                if (position > -1) {
                    this.selectedColor = this.selectedColors[position];
                    this.selectedImages = this.selectedImageUrls[position];
                    this.selectedCoord = this.selectedCoords[position];
                }
            }
        },
        editSetupProducts() {
            if (this.productEdit.store_default_vat !== undefined) {
                this.storeDefaultVat = this.productEdit.store_default_vat;
            }

            if (this.eventProduct?.store_default_vat !== undefined) {
                this.storeDefaultVat = this.eventProduct?.store_default_vat;
            }

            if (!this.edit.selected && this.selectedProduct.colours && this.selectedProduct.colours.length) {
                this.selectThisColor(this.selectedProduct.colours[0], 0, false);
            }

            if (!this.edit.selected && this.selectedProduct.values && this.selectedProduct.values.print_price) {
                this.setPrintPrice(this.selectedProduct.values.print_price);
                this.setSoloPriceFromPriceData(this.selectedProduct.values.print_price, 'print');
            }

            if (!this.edit.selected && this.selectedProduct.values && this.selectedProduct.values.blank_price) {
                this.setPrintPrice(this.selectedProduct.values.blank_price);
                this.setSoloPriceFromPriceData(this.selectedProduct.values.blank_price, 'blank');
            }

            if (this.edit.colour_size) {
                const colours = Object.keys(this.edit.colour_size);

                if (colours.length && this.mode === "consumer") {
                    this.selectThisColorConsumer(colours[0], false);
                }

                colours.forEach(data => {
                    this.selectedColor = data;
                    this.selectedCoord = this.edit.colour_coords[data];

                    this.selectedColors.push(this.selectedColor);

                    this.selectedSizes.push(this.edit.colour_size[data]);

                    this.selectedIdentifiers.push(this.edit.colour_identifiers[data]);

                    this.selectedImageUrls.push(this.edit.product_images[data]);

                    this.selectedSkus.push(this.edit.colour_skus[data]);

                    this.selectedCoords.push(this.edit.colour_coords[data]);
                });

                if (this.edit.selected && this.edit.selected.user_selected_product_color) {
                    const { user_selected_product_color } = this.edit.selected;

                    this.userSelectedProductColor = user_selected_product_color;
                }

                this.checkUserSelectedColor();

                if (this.selectedImageUrls.length) {
                    this.selectedImages = this.selectedImageUrls[0];
                }
            }

            if (this.productEdit.description) {
                this.productDescription = this.productEdit.description;
            } else if (this.selectedProduct?.values?.loc_product_description) {
                let desc;
                for (const availableLocale of this.selectedProduct?.values?.loc_product_description) {
                    if (availableLocale?.locale?.substr(0, 2) === this.$store.state.translation.lang) {
                        desc = availableLocale.data;
                        this.productDescription = desc.replace(/<p>|<b>|<\/b>|<\/p>/g, "");
                        break;
                    }
                }
            } else if (this.selectedProduct.values && this.selectedProduct.values.details_specs) {
                let desc = this.selectedProduct.values.details_specs[0].data || ""

                this.productDescription = desc.replace(/<p>|<b>|<\/b>|<\/p>/g, "");
            }

            if (this.productEdit.name) {
                this.productName = this.productEdit.name;
            } else if (this.selectedProduct?.values?.loc_product_name) {
                for (const availableLocale of this.selectedProduct?.values?.loc_product_name) {
                    if (availableLocale?.locale?.substr(0, 2) === this.$store.state.translation.lang) {
                        this.productName = availableLocale.data;
                        break;
                    }
                }
            } else if (this.selectedProduct.values && this.selectedProduct.values.name_) {
                this.productName = this.selectedProduct.values.name_[0].data || "";
            }

            // Event products have pricing set from another table
            // ------------------------------------------------------------------------------------
            if (this.productEdit.event_prices && this.productEdit.event_prices.length) {
                this.selectedSetPrice = this.productEdit.event_prices[0].store_selling_price;

                if (this.edit.selected && this.edit.selected.values) {
                    this.setOriginalProductPrice(this.edit.selected.values);
                }

            // Products created outside of an event won't have them, so we need to update the price
            // ------------------------------------------------------------------------------------
            } else if (this.edit.selected && this.edit.selected.values) {
                this.setOriginalProductPrice(this.edit.selected.values);

                const price = this.selectedProductPrice;

                this.selectedSetPrice = price + (price / 100 * this.productDefaultMarkup);
            }

            if (!this.selectedImages.front) this.setGenericProductImage();

            if (!this.edit.selected) {
                this.selectedSetPrice += (this.selectedSetPrice / 100 * this.productDefaultMarkup);
            }

            this.selectedSetPrice = parseFloat(this.selectedSetPrice).toFixed(2);

            this.checkMountedUserSelectedColor();
        },
        resetProductImageDisplayed() {
            const position = this.selectedColors.indexOf(this.selectedColor);

            if (this.selectedColors.length && position === -1) {
                this.selectedColor = this.selectedColors[0];
                this.selectedImages = this.selectedImageUrls[0];
            }

            this.checkUserSelectedColor();
        },
        setOriginalProductPrice(values) {
            if (values.print_price) {
                this.setPrintPrice(values.print_price, false);
                this.setSoloPriceFromPriceData(values.print_price, 'print');
            }

            if (values.blank_price) {
                this.setPrintPrice(values.blank_price, false);
                this.setSoloPriceFromPriceData(values.blank_price, 'blank');
            }
        },
        setSoloPriceFromPriceData(prices = [], blankOrPrint = "blank") {
            if (prices[0] && prices[0].data) {
                prices[0].data.forEach(data => {
                    if (data.currency === "GBP" && this.selectedRegion === "UK") {
                        blankOrPrint === 'blank' ? this.soloBlankPrice += parseFloat(data.amount) : this.soloPrintPrice += parseFloat(data.amount)
                    } else if (data.currency === "EUR" && this.selectedRegion === "EU") {
                        blankOrPrint === 'blank' ? this.soloBlankPrice += parseFloat(data.amount) : this.soloPrintPrice += parseFloat(data.amount)
                    } else if (data.currency === "USD" && this.selectedRegion === "US") {
                        blankOrPrint === 'blank' ? this.soloBlankPrice += parseFloat(data.amount) : this.soloPrintPrice += parseFloat(data.amount)
                    }
                });
            }
        },
        setPrintPrice(prices = [], same = true) {
            if (prices[0] && prices[0].data) {
                prices[0].data.forEach(data => {
                    if (data.currency === "GBP" && this.selectedRegion === "UK") {
                        this.selectedProductPrice += parseFloat(data.amount);

                        if (same) this.selectedSetPrice += parseFloat(data.amount);
                    } else if (data.currency === "EUR" && this.selectedRegion === "EU") {
                        this.selectedProductPrice += parseFloat(data.amount);

                        if (same) this.selectedSetPrice += parseFloat(data.amount);
                    } else if (data.currency === "USD" && this.selectedRegion === "US") {
                        this.selectedProductPrice += parseFloat(data.amount);

                        if (same) this.selectedSetPrice += parseFloat(data.amount);
                    }
                });
            }
        },
        setProductPrice(price) {
            if (price.store_selling_price) this.selectedSetPrice = price.store_selling_price;
        },
        selectThisColorComponent({ colour, index, select }) {
            this.selectThisColor(colour, index, true, select);
        },
        selectThisColor(value, index, renderCanvas = true, select = true) {
            const position = this.selectedColors.indexOf(value);

            if (position === -1) {
                this.selectedColor = value;

                this.selectedColors.push(this.selectedColor);

                this.selectedSizes.push([...this.selectedColorSizes]);

                this.selectedIdentifiers.push(this.selectedProduct.identifiers[index]);

                this.selectedImageUrls.push(this.selectedProduct.images[index]);

                this.selectedCoords.push(this.selectedProduct.coords[index]);

                this.selectedCoord = this.selectedProduct.coords[index];

                const color = this.selectedColors.indexOf(this.selectedColor);
                const identifier = this.selectedIdentifiers[color];

                const skus = [];

                this.checkUserSelectedColor();

                if (this.selectedColorSizes.length) {
                    this.selectedProduct.sizes.forEach((data, ind) => {
                        this.selectedColorSizes.forEach(size => {
                            if (data === `${identifier}_${size}`) {
                                skus.push(this.selectedProduct.skus[ind]);
                            }
                        });
                    });
                } else {
                    skus.push(this.selectedProduct.skus[index]);
                }

                this.selectedSkus.push([...skus]);

                if (this.selectedProduct.images && this.selectedProduct.images[index] && this.selectedColors.length == 1) {
                    this.selectedImages = this.selectedProduct.images[index];
                }

                if (!this.selectedImages?.front) this.setGenericProductImage();

                if (renderCanvas) this.checkWindow(false);
            } else if (select) {
                this.selectedColors.splice(position, 1);
                this.selectedIdentifiers.splice(position, 1);
                this.selectedImageUrls.splice(position, 1);
                this.selectedSizes.splice(position, 1);
                this.selectedSkus.splice(position, 1);
                this.selectedCoords.splice(position, 1);

                // If deselecting and there is only one added colour left, select it.
                // This shouldn't work but it works.
                if (this.selectedColors.length == 1) {
                    this.selectThisPreviewColor(
                        this.selectedColors[this.selectedColors.length - 1],
                        null,
                        this.selectedImageUrls[this.selectedImageUrls.length - 1]);
                }


                this.resetProductImageDisplayed();
            }
        },
        setGenericProductImage() {
            if (this.selectedProduct.values && this.selectedProduct.values.generic_front_image) {
                const images = this.selectedProduct.values.generic_front_image[0] || [];

                this.selectedImages.front = images._links.download.href || "";
            }
        },
        selectThisColorConsumer(value, renderCanvas = true) {
            this.selectedConsumerColor = value;
            this.selectedConsumerSizes = this.edit.colour_size[value] || [];

            const check = this.selectedConsumerSizes.indexOf(this.selectedConsumerSize);

            if (check > -1) {
                this.selectThisSizeConsumer(this.selectedConsumerSizes[check]);
            } else {
                this.selectThisSizeConsumer(this.selectedConsumerSizes[0]);
            }

            this.selectedConsumerIdentifier = this.edit.colour_identifiers[this.selectedConsumerColor];

            const selectedColourToPreviewFront = this.edit.artwork.front_colours.find(item => item.colour == value);
            this.frontPreviewConsumer = `${this.$config.s3Bucket}/${this.$config.environment}/${selectedColourToPreviewFront.img}`;
            if (this.edit.artwork.back_colours.length > 0) {
                const selectedColourToPreviewBack = this.edit.artwork.back_colours.find(item => item.colour == value);
                this.backPreviewConsumer = `${this.$config.s3Bucket}/${this.$config.environment}/${selectedColourToPreviewBack.img}`;
            }

            this.selectedImages = this.edit.product_images[value];

            // if (renderCanvas) this.checkWindow(false);
        },
        selectThisSize(value) {
            const position = this.selectedColors.indexOf(this.selectedColor);

            this.selectedSize = this.selectedSizes[position];
            this.selectedSkusSelected = this.selectedSkus[position];

            const sizePosition = this.selectedSize.indexOf(value);

            if (sizePosition === -1) {
                const identifier = this.selectedIdentifiers[position];
                const size = this.selectedProduct.sizes.indexOf(`${identifier}_${value}`);
                const sku = this.selectedProduct.skus[size];

                this.selectedSize.push(value);
                this.selectedSkusSelected.push(sku);
            } else {
                this.selectedSize.splice(sizePosition, 1);
                this.selectedSkusSelected.splice(sizePosition, 1);
            }

            this.selectedSizes[position] = this.selectedSize;
            this.selectedSkus[position] = this.selectedSkusSelected;
        },
        selectThisSizeConsumer(value) {
            this.selectedConsumerSize = value;

            const position = this.selectedSizesConsumer.indexOf(value);

            this.selectedConsumerSku = this.selectedSkusConsumer[position];
        },
        searchForColourTitle(color) {
            let output = "";

            if (this.selectedProduct.colour_names && this.selectedProduct.colour_names.length) {
                const position = this.selectedProduct.colours.indexOf(color);

                if (position > -1 && this.selectedProduct.colour_names[position]) {
                    output = this.selectedProduct.colour_names[position].toLowerCase();

                    return output

                }
            }

            return output;
        },
        selectDeselectAllAvailableColours() {
            if (this.selectedProduct.colours.length !== this.selectedColors.length) {
                this.selectedProduct.colours.forEach((color, index) => {
                    if (this.selectedColors.indexOf(color) === -1) {
                        this.selectThisColor(color, index);
                    }
                });
            } else if (this.selectedProduct.colours.length === this.selectedColors.length) {
                this.selectedProduct.colours.forEach((color, index) => {
                    if (this.selectedColors.indexOf(color) > -1) {
                        this.selectThisColor(color, index);
                    }
                });
            }
        },
        selectThisPreviewColor(color, index = null, selectedImageUrls = null) {
            this.userSelectedProductColor = color;

            if (index !== null) {
                this.selectedImages = this.selectedProduct.images[index];
            } else if (selectedImageUrls) {
                this.selectedImages = selectedImageUrls;
            }
        },
        updateProductForm(val) {
            this[val.key] = val.value;
        }
    }
}