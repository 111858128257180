/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorRender = {
    methods: {
        scaleCanvasObjects(height, width, calc, load = false) {
            let objects = this.canvas.getObjects();

            for (let i in objects) {
                objects[i].scaleX = objects[i].scaleX * calc;
                objects[i].scaleY = objects[i].scaleY * calc;
                objects[i].left = objects[i].left * calc;
                objects[i].top = objects[i].top * calc;
                objects[i].setCoords();
            }

            this.canvas.setHeight(height);
            this.canvas.setWidth(width);
            this.canvas.requestRenderAll();
            this.canvas.calcOffset();

            this.loading = load;
        },
        scaleCanvasUpPrint(load = true) {
            const { backHeightPrintPixels, heightPrintPixels, widthPrintPixels } = this.productPrintArea;

            const scaleFactor = 1000000;
            const scaledNumerator = widthPrintPixels * scaleFactor;
            const scaledDenominator = this.canvas.getWidth() * scaleFactor;
            const calc = scaledNumerator / scaledDenominator;

            const height = this.angle === "back" && backHeightPrintPixels
            ? backHeightPrintPixels
            : heightPrintPixels;

            this.scaleCanvasObjects(height, widthPrintPixels, calc, load);
        },
        scaleCanvasDownDisplay(height, width, display) {
            const calc = width / this.canvas.getWidth();

            let objects = this.canvas.getObjects();

            for (let i in objects) {
                objects[i].scaleX = objects[i].scaleX * calc;
                objects[i].scaleY = objects[i].scaleY * calc;
                objects[i].left = objects[i].left * calc;
                objects[i].top = objects[i].top * calc;

                if (objects[i].centralTextPoint) {
                    objects[i].centralTextPoint.x = objects[i].centralTextPoint.x * calc;
                    objects[i].centralTextPoint.y = objects[i].centralTextPoint.y * calc;
                }

                objects[i].setCoords();
            }

            const take = (width - display) / 2;

            width = display;

            for (let i in objects) {
                objects[i].left = objects[i].left - take;

                objects[i].setCoords();
            }

            this.canvas.setHeight(height);
            this.canvas.setWidth(width);
            this.canvas.requestRenderAll();
            this.canvas.calcOffset();
        },
        scaleCanvasUpDisplayPrint(load = true) {
            const {
                backHeightPrintPixels, heightPrintPixels, widthPrintPixels, widthPixels, scaledPercentage
            } = this.productPrintArea;

            const backup = this.productPrintArea.widthConverted;

            this.productPrintArea.widthConverted = widthPixels / 100 * scaledPercentage;

            const { backHeightConverted, heightConverted, widthConverted } = this.productPrintArea;

            const take = (widthConverted - backup) / 2;

            let objects = this.canvas.getObjects();

            for (let i in objects) {
                objects[i].left = objects[i].left + take;

                objects[i].setCoords();
            }

            const conversion = this.angle === "back" && backHeightConverted
            ? backHeightConverted
            : heightConverted;

            this.canvas.setHeight(conversion);
            this.canvas.setWidth(widthConverted);
            this.canvas.requestRenderAll();
            this.canvas.calcOffset();

            const calc = widthPrintPixels / this.canvas.getWidth();

            const height = this.angle === "back" && backHeightPrintPixels
            ? backHeightPrintPixels
            : heightPrintPixels;

            this.scaleCanvasObjects(height, widthPrintPixels, calc, load);
        },
        scaleCanvasUp(load = false) {
            const { backHeightPrintPixels, heightPixels, widthPixels } = this.productPrintArea;

            const scaleFactor = 1000000;
            const scaledNumerator = widthPixels * scaleFactor;
            const scaledDenominator = this.canvas.getWidth() * scaleFactor;
            const calc = scaledNumerator / scaledDenominator;

            const height = this.angle === "back" && backHeightPrintPixels
            ? backHeightPrintPixels
            : heightPixels;

            this.scaleCanvasObjects(height, widthPixels, calc, load);
        },
        scaleCanvasDown(load = false) {
            const { backHeightConverted, heightConverted, widthConverted } = this.productPrintArea;

            const scaleFactor = 1000000;
            const scaledNumerator = widthConverted * scaleFactor;
            const scaledDenominator = this.canvas.getWidth() * scaleFactor;
            const calc = scaledNumerator / scaledDenominator;

            const height = this.angle === "back" && backHeightConverted
            ? backHeightConverted
            : heightConverted;

            this.scaleCanvasObjects(height, widthConverted, calc, load);
        },
        scaleCanvasSpecific(height, width) {
            const scaleFactor = 1000000;
            const scaledNumerator = width * scaleFactor;
            const scaledDenominator = this.canvas.getWidth() * scaleFactor;
            const calc = scaledNumerator / scaledDenominator;

            this.scaleCanvasObjects(height, width, calc, true);
        },
    }
}