/**
 * @author Jason Curren
 * @description
 */

export const setFonts = {
    data() {
        return {
            fontSearchString: ""
        }
    },
    computed: {
        fontsearch() {
            let output = this.$store.state.fonts.fonts;

            if (this.fontSearchString) {
                output = output.filter(data => {
                    const check = data.family.toLowerCase().replace(/ /g, "");
                    const search = this.fontSearchString.toLowerCase().replace(/ /g, "");

                    if (check.includes(search)) return data;
                });
            }

            return output;
        }
    },
    methods: {
        getFonts() {
            this.$axios.get(`/api/fonts`, {
            }).then(res => {
                this.$store.commit("fonts/SET_FONTS", res.data.items);

                this.updateFonts();
            }).catch(err => {
                console.log(err);
            });
        },
        updateFonts() {
            this.$store.state.fonts.fonts.forEach(data => {
                if (data.files.regular) {
                    const font = new FontFace(
                        data.family,
                        `url(${data.files.regular})`,
                        {}
                    );

                    document.fonts.add(font);

                    font.load();
                }
            });
        }
    }
}