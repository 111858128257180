/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorControls = {
    data() {
        return {
            guide: false,
            grid: true,
            preview: false,
            previewImage: ""
        }
    },
    methods: {
        async previewArtwork() {
            this.loading = true;
            this.preview = true;

            this.canvas._objects.forEach((data, index) => {
                if (data._element) this.canvas._objects[index]._element.crossOrigin = "anonymous";
            });

            await setTimeout(() => this.scaleCanvasUp(), 400);

            await setTimeout(() => {
                this.previewImage = this.canvas.toDataURL("png");
                this.loading = false;
            }, 600);

            await setTimeout(() => {
                this.canvas._objects.forEach((data, index) => {
                    if (data._element) this.canvas._objects[index]._element.crossOrigin = "";
                });
            }, 800);

            await setTimeout(() => this.scaleCanvasDown(), 1000);
        },
        previewGuide() {
            this.guide = !this.guide;
        },
        previewGrid() {
            this.grid = !this.grid;
        },
        unpreviewArtwork() {
            this.preview = false;
            this.previewImage = "";
        },
    }
}