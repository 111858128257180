/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorEdit = {
    
    data() {
        return {
            editLoading: false,
            loading: false
        }
    },
    props: {
        edit: {
            default: () => ({}),
            type: Object
        }
    },
    computed: {
        editProduct() {
            let output = false;

            Object.values(this.edit).forEach(data => {
                if (data.length) output = true;
            });

            return output;
        }
    },
    methods: {
        async checkEdit() {
            this.editLoading = true;
            if (this.edit.back?.length) {
                this.angle = "back";

                for (const data of this.edit.back) {
                    if (data.type === "Image") {
                        await this.checkEditImage(data);
                    } else if (data.type === "Text") {
                        await this.checkEditText(data);
                    }
                }

                this.canvas.renderAll();
                this.scaleCanvasDown();
                if (this.edit?.front.length == 0) {
                    if (this.mode === "editor") {
                        this.angle = "front";
                        this.renderCache("front");
                    }
                    this.editLoading = false;
                    this.loading = false
                    return;
                }
                this.updateCache("back");
                this.renderCache("back");
                this.updateAngle("front");
                this.scaleCanvasUpPrint(true);
            }

            if (this.edit?.front.length) {
                this.angle = "front";
                this.renderCache("front");

                for (const data of this.edit.front) {
                    if (data.type === "Image") {
                        await this.checkEditImage(data);
                    } else if (data.type === "Text") {
                        await this.checkEditText(data);
                    }
                }

                this.updateCache("front");
                this.renderCache("front");
                this.canvas.renderAll();
                this.scaleCanvasDown();
                this.editLoading = false;
                this.loading = false
            }

            if (this.mode === "editor") {
                this.selectThisPreviewColor(
                    this.userSelectedProductColor,
                    this.selectedProduct.colours.indexOf(this.userSelectedProductColor)
                );
            }
        },
        checkEditImage(val) {
            return new Promise((resolve, reject) => {
                const { angle, filename, left, location, scaleX, scaleY, top, image_resized } = val;
                this.$fabric.Image.fromURL(`/${(image_resized || location)}`, img => {
                    if (!img) return reject(new Error("Image loading failed"));

                    let settings = {
                        name: `image-${Date.now()}`,
                        angle, filename, left, top, location, scaleX, scaleY, image_resized
                    };

                    if (this.mode === "consumer") {
                        settings.hoverCursor = "default";
                        settings.selectable = false;
                    } else {
                        settings.centeredScaling = true;
                    }

                    img.setControlsVisibility({
                        mb: false, ml: false, mr: false, mt: false, mtr: false
                    });

                    this.canvas.add(img.set(settings));
                    this.addImageCreatorEventListeners(img);
                    resolve();
                });
            });
        },
        checkEditText(val) {
            return new Promise((resolve) => {
                let {
                    angle, fontFamily, height, left, textAlign, text, top, width,
                    fontSize, fontStyle, fontWeight, underline, customisable,
                    shadow, stroke, fill, scaleX, scaleY, centralTextPoint,
                    fontUrl
                } = val;

                fontSize = this.addTextFontSize(fontSize);

                let textSettings = {
                    name: `text-${Date.now()}`,
                    fontFamily, objectCaching: false,
                    textAlign, dirty: true, statefullCache: false,
                    selected: true, height, width, fontSize, fontStyle, fontWeight,
                    underline, shadow, stroke, fill, editable: false,
                    scaleX, scaleY, customisable, angle, fontUrl
                };

                if (this.mode === "consumer" || this.mode === "builder") {
                    textSettings.hoverCursor = customisable ? "text" : "default";
                    textSettings.selected = false;
                    textSettings.selectable = customisable ? true : false;
                    textSettings.top = top;
                    textSettings.left = left;

                    const textBox = new this.$fabric.IText(text, textSettings);

                    textBox.setControlsVisibility({
                        bl: false, br: false, mb: false, ml: false, mr: false, mt: false,
                        mtr: false, tl: false, tr: false
                    });

                    this.canvas.add(textBox);

                    if (this.mode === "builder") {
                        const position = (textBox.width * textBox.scaleX) / 2;
                        textBox.left = centralTextPoint.x - position;
                        textBox.setCoords();
                    }

                    this.addTextCreatorConsumerEventListeners(textBox);
                    if (textBox.customisable) {
                        this.canvas.setActiveObject(textBox);
                        textBox.borderColor = "transparent";
                    }
                } else {
                    textSettings.centeredScaling = true;
                    textSettings.selected = true;
                    textSettings.left = left;
                    textSettings.top = top;

                    const textBox = new this.$fabric.IText(text, textSettings);

                    textBox.setControlsVisibility({
                        mb: false, ml: false, mr: false, mt: false, mtr: false
                    });

                    this.canvas.add(textBox);
                    this.addTextCreatorEventListeners(textBox);
                }
                resolve();
            });
        },
        closeAsset() {
            this.updateBoxtype("");
        }
    }
}