/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

import { setFonts } from "@/mixins/fonts/setFonts";

export const creatorUpdates = {
    mixins: [setFonts],
    props: {
        items: {
            default: () => ([]),
            type: Array
        },
        selectedProduct: {
            default: () => ({}),
            type: Object
        }
    },
    data() {
        return {
            activeReset: false,
            add: "",
            angle: "front",
            control: "Text",
            colorPicker: "",
            modalType: "",
            modalIsLoading: false,
            product: {},
            selected: {
                colour: "white",
                size: "l"
            }
        }
    },
    methods: {
        addColour() {
            setTimeout(() => this.openColor(), 100);
        },
        addColorModal() {
            this.modalType = "color";
        },
        addFontModal() {
            this.modalType = "font";

            this.addAFont();
        },
        addThisColor() {
            if (this.colorPicker) {
                this.finalDesign.colours.push(this.colorPicker);

                this.closeModal();
            }
        },
        addThisFont(font) {
            this.finalDesign.fonts.push(font);

            let find = false;
            Object.entries(this.fonts).forEach(([key, val]) => {
                if (!find && !val) {
                    find = true;

                    this.fonts[key] = font;
                }
            });

            this.$axios.patch(`${this.auth_route}/font-update`, {
                fonts: this.fonts }, {
            }).catch(res => {
                console.log(res);
            });
            this.closeModal();
        },
        addAFont() {
            this.modalIsLoading = true;

            if (!this.$store.state.fonts.fonts.length) this.getFonts();

            setTimeout(() => this.checkFonts(), 1000);
        },
        checkFonts() {
            if (this.$store.state.fonts.fonts.length) {
                this.modalIsLoading = false;
            } else {
                setTimeout(() => this.checkFonts(), 1000);
            }
        },
        checkForEditableConsumerText() {
            const objs = this.canvas.getObjects();

            objs.forEach(data => {
                if (data.text && data.customisable) {
                    this.canvas.setActiveObject(data);
                }
            });
        },
        closeModal() {
            this.canvasPreviewImages = [];
            this.modalType = "";
        },
        openColor() {
            const color = document.getElementById("color-picker");

            if (color) color.click();
        },
        /**
         * Updates the current cache ('frontCache' or 'backCache') with what's on the canvas.
         * Renders the cache of the other angle on the canvas.
         *
         * @param {string} newAngleValue - A new value for the angle ('front' or 'back') of the product, representing the perspective.
         * @returns {undefined}
         */
        changeViewingAngleConsumer(newAngleValue) {
            // Update the cache of the current angle.
            this.updateCache(this.angle);
            // Render the cache of the new angle on the canvas.
            this.renderCache(newAngleValue);
            // Change the angle after rendering.
            this.angle = newAngleValue;
        },
        updateAngle(val) {
            this.updateCache(this.angle);
            this.renderCache(val);

            this.checkWindow(false);

            this.angle = val;
        },
        updateAssetCheckCustomText() {
            const objects = this.canvas.getObjects();

            objects.forEach(data => {
                if (data.customisable) {
                    this.canvas.setActiveObject(data);
                    this.selectedText = data;
                }
            });
        },
        updateAsset(val) {
            if (val === "text") this.updateAssetCheckCustomText();

            this.asset = val;

            this.updateBoxtype(val);
        },
        updateBoxtype(val) {
            this.add = val;

            if (!this.add) {
                this.selectedImage = {};
                this.selectedText = {};
            } else {
                const check = this.canvas.getActiveObject() || false;

                if (check) {
                    this.activeReset = true;
                    this.resetSelected();
                }
            }

            this.updateControl(val);
        },
        updateControl(val) {
            this.control = val;
        },
        updateColour(val) {
            this.selected.colour = val;
        },
        updateProduct(val) {
            this.product = val;

            this.canvas.clear();

            this.canvas.setWidth(this.size / 2);
        },
        updateSize(val) {
            this.selected.size = val;
        },
        updateText(val) {
            this.addTextCheck(val);
        },
        resetSelected() {
            this.canvas.discardActiveObject().renderAll();
        }
    },
    watch: {
        angle() {
            if (this.mode === "consumer") {
                this.checkForEditableConsumerText();
            }
        }
    }
}