
export default {
    name: "SomethingWentWrongModal",
    methods: {
        reloadPageAfterClick() {
            window.location.reload();
            return false;
        },
    }
}
