
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    export default {
        name: "CreatorAssets",
        props: {
            active: {
                default: "",
                type: String
            },
            add: {
                default: "",
                type: String
            },
            brandColors: {
                default: () => ([]),
                type: Array
            },
            buttons: {
                default: () => ([
                    { icon: "FixedText", title: "Fixed", value: "fixed", view: "fixed_text" },
                    { icon: "CustomText", title: "Text", value: "text", view: "custom_text" },
                    { icon: "Assets", title: "Assets", value: "assets", view: "assets" },
                    { icon: "Layers", title: "Layers", value: "layers", view: "layers" }
                ]),
                type: Array
            },
            control: {
                default: "",
                type: String
            },
            doeWeHaveCustomisableText: {
                default: false,
                type: Boolean
            },
            extraColours: {
                default: () => ([]),
                type: Array
            },
            extraFonts: {
                default: () => ([]),
                type: Array
            },
            fonts: {
                default: () => ([]),
                type: Array
            },
            files: {
                default: () => ([]),
                type: Array
            },
            items: {
                default: () => ([]),
                type: Array
            },
            objects: {
                default: () => ([]),
                type: Array
            },
            otherProductColours: {
                default: () => ([]),
                type: Array
            },
            otherProductFonts: {
                default: () => ([]),
                type: Array
            },
            product: {
                default: () => ({}),
                type: Object
            },
            selected: {
                default: () => ({}),
                type: Object
            },
            selectedImage: {
                default: () => ({}),
                type: Object
            },
            selectedRegion: {
                default: "UK",
                type: String
            },
            selectedText: {
                default: () => ({}),
                type: Object
            },
            url: {
                default: "",
                type: String
            }
        },
        data() {
            return {
                modalType: "",
            }
        },
        computed: {
            availableComponents() {
                let output = {};

                this.buttons.forEach(data => output[data.value] = data.title);

                return output;
            }
        },
        methods: {
            closePopup(val) {
                this.$emit("update-asset", val);
            },
            closeAsset() {
                this.updateBoxtype("");
            },
            editAsset(value) {
                this.$emit("update-asset", value);
            },
            addImg(event) {
                this.$emit("add-img", event);
            },
            openFilebox() {
                this.updateBoxtype("File");
            },
            openTextbox() {
                this.updateBoxtype("Text");

                setTimeout(() => this.$emit("reset-selected"), 100);
            },
            resetSelected() {
                this.$emit("reset-selected");
            },
            updateBoxtype(val) {
                this.$emit("update-boxtype", val);
            },
            updateColour(val) {
                this.$emit("update-colour", val);
            },
            updateControl(val) {
                this.$emit("update-control", val);
            },
            updateImage(val) {
                this.$emit("update-image", val);
            },
            updateProduct(val) {
                this.$emit("update-product", val);
            },
            updateSize(val) {
                this.$emit("update-size", val);
            },
            updateText(val) {
                this.$emit("update-text", val);
            },
            deleteLayerObject(obj) {
                this.$emit("delete-layer-object", obj);
            },
            downLayerObject({ obj, index }) {
                this.$emit("down-layer-object", { obj, index });

                if (this.mode === "editor") {
                    this.updateObjectPreview();
                }
            },
            editLayerObject(obj) {
                this.$emit("edit-layer-object", obj);
            },
            upLayerObject({ obj, index }) {
                this.$emit("up-layer-object", { obj, index });
            },
            updateModalType(type) {
                this.modalType = type;
            },
            updateFileName(filename) {
                this.$emit("update-file-name", filename);
            }
        }
    }
