import { render, staticRenderFns } from "./CreatorArea.vue?vue&type=template&id=9702c878"
import script from "./CreatorArea.vue?vue&type=script&lang=js"
export * from "./CreatorArea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/usr/src/eventum-portal/components/icon/IconClose.vue').default,ModalOverlay: require('/usr/src/eventum-portal/components/modal/ModalOverlay.vue').default,ModalCreator: require('/usr/src/eventum-portal/components/modal/creator.vue').default,ModalPreview: require('/usr/src/eventum-portal/components/modal/preview.vue').default,ModalConfirm: require('/usr/src/eventum-portal/components/modal/confirm.vue').default,IconBin: require('/usr/src/eventum-portal/components/icon/bin.vue').default,IconLoader: require('/usr/src/eventum-portal/components/icon/IconLoader.vue').default,CreatorCanvas: require('/usr/src/eventum-portal/components/creator/CreatorCanvas.vue').default,CreatorCanvasEditor: require('/usr/src/eventum-portal/components/creator/CreatorCanvasEditor.vue').default,CreatorAssets: require('/usr/src/eventum-portal/components/creator/CreatorAssets.vue').default,CreatorProductColours: require('/usr/src/eventum-portal/components/creator/CreatorProductColours.vue').default,CreatorCanvasPreview: require('/usr/src/eventum-portal/components/creator/CreatorCanvasPreview.vue').default,IconBold: require('/usr/src/eventum-portal/components/icon/IconBold.vue').default,IconItalic: require('/usr/src/eventum-portal/components/icon/IconItalic.vue').default,IconBullets: require('/usr/src/eventum-portal/components/icon/bullets.vue').default,IconOrdered: require('/usr/src/eventum-portal/components/icon/ordered.vue').default,CreatorProductSizes: require('/usr/src/eventum-portal/components/creator/CreatorProductSizes.vue').default,FormInput: require('/usr/src/eventum-portal/components/form/FormInput.vue').default,ProductDeliveryDate: require('/usr/src/eventum-portal/components/product/ProductDeliveryDate.vue').default,ProductCheckbox: require('/usr/src/eventum-portal/components/product/ProductCheckbox.vue').default})
