
    import { creatorCanvasProps } from "@/mixins/creator/creatorCanvasProps";

    /**
     * @author Jason Curren
     * @description Provides a print/canvas area to configure products
     * ============================================================================================
     */
    export default {
        name: "CreatorCanvasEditor",
        mixins: [creatorCanvasProps]
    }
