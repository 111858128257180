/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorLayers = {
    methods: {
        deleteLayerObject(obj) {
            this.canvas.remove(obj);

            this.canvas.requestRenderAll();

            this.updatePreviewCanvasDelete(obj);
        },
        downLayerObject({ obj, index }) {
            const objects = this.getLayerObjects(obj);

            objects.splice(index - 1, 0, obj);

            this.setLayerObjects(objects);
        },
        editLayerObject(obj) {
            this.canvas.setActiveObject(obj);

            this.canvas.requestRenderAll();
        },
        getLayerObjects(obj) {
            this.imageLoading = true;

            this.canvas.remove(obj);

            const objects = this.canvas.getObjects();

            this.canvas.clear();

            return objects;
        },
        setLayerObjects(objects) {
            const newObjects = [];

            objects.forEach(data => {
                const type = data.hasOwnProperty("text")
                ? this.addProductText(data)
                : this.addProductImage(data);

                newObjects.push(type);
            });

            let timer = 0;

            newObjects.forEach((data, index) => {
                setTimeout(() => this[`checkEdit${data.type}`](data), timer);

                timer += 200;

                if (index + 1 === objects.length) {
                    this.canvas.requestRenderAll();

                    setTimeout(() => {
                        this.imageLoading = false;

                        if (this.mode === "editor" && this.canvasPreview) {
                            this.imageLoading = true;

                            this.updateObjectPreviewLayersRefresh(newObjects);
                        }
                    }, timer);
                }
            });
        },
        upLayerObject({ obj, index }) {
            const objects = this.getLayerObjects(obj);

            if (index === objects.length && objects.length > 2) {
                index -= 2;
            }

            console.log(obj, index);

            objects.splice(index + 1, 0, obj);

            this.setLayerObjects(objects);
        }
    }
}