
    export default {
        name: "ModalCreator",
        props: {
            button: {
                default: "Close",
                type: String
            },
            loading: {
                default: false,
                type: Boolean
            },
            logo: {
                default: "",
                type: String
            },
            storeUrl: {
                default: "",
                type: String
            },
            title: {
                default: "",
                type: String
            }
        },
        methods: {
            returnToStore(url){
                window.location.href = url;
            }
        }
    }
