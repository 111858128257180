
    export default {
        name: "ProductCheckbox",
        props: {
            disabled: {
                default: false,
                type: Boolean,
            },
            label: {
                default: "",
                type: String,
            },
            input: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                internalInput: {
                    default: this.input,
                    type: Boolean
                }
            }

        },
        watch: {
            internalInput() {
                this.$emit("update-form", this.internalInput);
            },
        }
    }
