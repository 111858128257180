
    export default {
        name: "ModalConfirm",
        props: {
            allowSaveAsDraft: {
                default: false,
                type: Boolean
            },
            title: {
                default: "Are you sure you want to leave",
                type: String
            }
        }
    }
