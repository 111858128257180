
    import { creatorCanvasProps } from "@/mixins/creator/creatorCanvasProps";

    /**
     * @author Jason Curren
     * @description preview for canvas editor design
     * ============================================================================================
     */
    export default {
        name: "CreatorCanvasPreview",
        mixins: [creatorCanvasProps],
        props: {
            selectedPreviewImage: {
                default: () => ({
                    url: ""
                }),
                type: Object
            }
        },
        computed: {
            canvasPositionPreview() {
                let output = this.canvasPosition;

                if (this.selectedPreviewImage.left & this.canvasCoords.y) {
                    const x = (parseInt(this.selectedPreviewImage.left) / 100) * this.print.imageScaledPercentage;
                    const y = (this.canvasCoords.y / 100) * this.print.imageScaledPercentage;

                    output = `left: ${x}px; position: absolute; top: ${y}px`;
                }

                return output;
            },
            previewImages() {
                const output = [];

                if (this.images.angles && this.images.angles.length) {
                    this.images.angles.forEach((data, index) => {
                        output.push({ ...data, index });
                    });
                } else if (this.imageFiles.back && this.imageFiles.front) {
                    output.push(
                        { angle: "front", url: this.imageFiles.front },
                        { angle: "back", url: this.imageFiles.back }
                    );
                }

                return output;
            }
        }
    }
