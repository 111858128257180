/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorImage = {
    data() {
        return {
            selectedImage: {},
        };
    },
    methods: {
        addImageCreatorEventListeners(img) {
            img.on({
                deselected: this.unselectImage,
                moving: this.updatePreviewCanvasPositions,
                scaling: this.updatePreviewCanvasPositions,
                rotating: this.selectImageRotating,
            });
        },
        addImg(event) {
            this.$emit("add-img", event);
        },
        addImage(val) {
            const { location, original_filename, image_resized } = val;
            this.$fabric.Image.fromURL(`/${image_resized}`, (img) => {
                if (this.canvas.getHeight() > this.canvas.getWidth()) {
                    img.scaleToWidth(this.canvas.getWidth() / 2);
                } else {
                    img.scaleToHeight(this.canvas.getHeight() / 2);
                }

                img.centeredScaling = true;

                img.name = `image-${Date.now()}`;
                img.setControlsVisibility({
                    mb: false,
                    ml: false,
                    mr: false,
                    mt: false,
                    mtr: false,
                });

                img.image_resized = image_resized;
                img.location = location;

                this.canvas.add(
                    img.set({ left: 0, top: 0, lockScalingFlip: true })
                );

                this.addImageCreatorEventListeners(img);

                img.filename = original_filename;

                this.canvas.centerObject(img);
                this.canvas.setActiveObject(img);

                if (this.mode === "editor" && !this.isACylindricalProduct) this.updatePreviewCanvas();
            });
        },
        addUpdateImage(active, val) {
            let { key, value } = val;

            if (key !== "position") {
                this.updateImageObject(key, active, value);
            } else {
                this[`update${value}Align`](active);
            }

            this.canvas.requestRenderAll();
        },
        closeSelectedAsset() {
            this.selectedImage = {};

            this.canvas.discardActiveObject().requestRenderAll();
        },
        updateImage(val) {
            const active = this.canvas.getActiveObject() || false;

            !active ? this.addImage(val) : this.addUpdateImage(active, val);
        },
        updateImageObject(key, active, value) {
            key && key === "angle"
                ? active.rotate(value)
                : (active[key] = value);
        },
        selectImage() {
            const check = this.canvas.getActiveObject() || false;

            if (check) {
                this.selectedImage = { ...check };
                this.asset = this.add = "assets";
                this.selectedText = {};
            } else {
                this.asset = this.add = "";
            }
        },
        selectImageRotating() {
            const active = this.canvas.getActiveObject() || false;

            if (active) this.selectedImage = { ...active };
        },
        updateFileName(filename) {
            const active = this.canvas.getActiveObject() || false;

            if (active) active.filename = filename;
        },
        unselectImage() {
            if (!this.activeReset) {
                this.selectedImage = {};

                this.updateBoxtype("");
            } else {
                this.activeReset = false;
            }
        },
    },
};
