/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorCache = {
    data() {
        return {
            backCache: [],
            frontCache: [],
            left_sleeveCache: [],
            right_sleeveCache: []
        }
    },
    methods: {
        renderCache(key) {
            if (this.mode === "editor") this.canvas.discardActiveObject();

            this.canvas._objects = [...this[`${key}Cache`]];

            this.canvas.requestRenderAll();
        },
        updateCache(key) {
            this[`${key}Cache`] = [...this.canvas._objects];
        }
    }
}