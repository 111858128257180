
    export default {
        name: "CreatorProductSizes",
        props: {
            selectedColorSizes: {
                default: () => ([]),
                type: Array
            },
            sizeFormatting: {
                default: () => ({}),
                type: Object
            }
        }
    }
